import {BaseService} from './base.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import {forkJoin, Observable, of } from 'rxjs';
import {AppSettings} from '../app.settings';
import {ToastrService} from 'ngx-toastr';

import lodashFilter from 'lodash/filter';
import {Response} from '../models/response.model';
import {Entity} from '../models/entity.model';
import {EntityLocation} from '../models/entity-location.model';
import {isEmptyArray} from '../shared/utils';
import {AuthService} from './auth.service';
import {API_URL} from '../constants/api-urls';

@Injectable()
export class EntityService extends BaseService {

    private cacheKey = 'entities';
    private prefetchedCapexEntities: number[] = [];

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        private authService: AuthService,
    ) {
        super('/entity', http, appSettings, toastr);
    }

    get(entityID: number): Observable<Entity> {
        const key: string = this.cacheKey + '-' + entityID;

        if (this.cache.has(key)) {
            return of(this.cache.get(key));
        }

        return this.http.get<Response>(this.apiURL + '/' + entityID)
            .pipe(
                map(response => {
                    const result: Entity = new Entity(response.data);

                    this.cache.set(key, result);

                    return result;
                }),
                catchError(this.handleError('EntityService::get', null))
            );
    }

    // this returns a limited result, entity name, ID and external_id only
    search(match: string, paging: string = '25-0'): Observable<Entity[]> {
        if (!match) {
            return of([]);
        }
        const headers: HttpHeaders = new HttpHeaders({'Range': paging});
        const key: string = btoa(JSON.stringify({s: match, p: paging})).replace('=', '');

        if (this.cache.has(key)) {
            return of(this.cache.get(key));
        }

        return this.http.post<HttpResponse<Response>>(this.computeApiURL('/entities'),
            {match: match}, {observe: 'response', headers: headers}
        ).pipe(
            map(httpResponse => {
                const response = new Response(httpResponse.body);
                const result = response.data;

                // ! maintain "count" in state and use for pagination and limit in requests
                // const count = httpResponse.headers.get('X-Count');
                let data: Entity[] = [];
                if (!isEmptyArray(result)) {
                    data = result.map(i => new Entity(i));
                    // exclude entity with the same as user entity id
                    data = data.filter( i => i.id !== this.authService.user.entity_id);
                }

                this.cache.set(key, data);
                return data;
            }),
            catchError(this.handleError('EntityService::search', []))
        );
    }

    locations(entityID: number, filter: object = null): Observable<EntityLocation[]> {
        const key = `locations-${entityID}`;

        if (this.cache.has(key)) {
            let result = this.cache.getItems(key);

            if (filter) {
                result = lodashFilter(result, filter);
            }

            return of(result);
        }

        return this.getLocations()
            .pipe(
                map( (res: EntityLocation[]) => {
                    const locationResult: EntityLocation[] = res;
                    this.cache.setItems(key, locationResult);
                    if (filter) {
                        return lodashFilter(locationResult, filter) as EntityLocation[];
                    }
                    return locationResult;
                })
            );
    }

    private getLocations(): Observable<EntityLocation[]> {
        const url = `${API_URL}/entities/locations`;

        return this.http.get<Response>(url)
            .pipe(
                map(response => {
                    if (Array.isArray(response.data)) {
                        return response.data.map(i => new EntityLocation(i));
                    }
                    return [];
                }),
                catchError(this.handleError('EntityService::locations', []))
            );
    }


    shippingAddresses(entityID: number): Observable<any[]> {
        return this.locations(entityID, {type: 'SHIPPING_LOCATION'});
    }

    getDefaultShippingLocation(entityId: number): Observable<EntityLocation> {
        return this.shippingAddresses(entityId).pipe(
            map((entities: EntityLocation[]) => {
                const entity: EntityLocation = entities.find(it => (it.is_default));

                if (!entity) {
                    throw new Error('Default address does not exist!');
                }
                return entity;
            }),
            catchError(this.handleError('EntityService::default-location', null))
        );
    }


    getEntityWithLocations(entityId: number): Observable<Entity> {
        return forkJoin([this.get(entityId), this.locations(entityId, null)]).pipe(
            map(result => {
                const entity: Entity = result[0];
                entity.locations = result[1] || [];
                return entity;
            })
        );
    }


    public prefetchCapex(entityId: number): Observable<boolean> {

        if (this.prefetchedCapexEntities.includes(entityId)) {
            return of (true);
        }

        return this.http.get<Response>(`${this.apiURL}/${entityId}/pre-fetch-capex`).pipe(
            map( response => {
                return !!response?.data;
            }),
            tap( () => {
                this.prefetchedCapexEntities.push(entityId);
            }),
            catchError(this.handleError('EntityService::prefetchCapex', false))
        )
    }

    public reloadCapex(entityId: number): Observable<string> {
        return this.http.get<Response>(`${this.apiURL}/${entityId}/reload-capex`).pipe(
            map( response => {
                return response?.data;
            }),
            catchError(this.handleError('EntityService::reloadCapex', ''))
        )
    }
}
