import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize } from '../../../ngx-gallery';

import { Product } from '../../../models/product.model';
import {MobileCheckServiceService} from '../../../services/mobile-check-service.service';
import {Order} from '../../../models/order.model';
import {OrderItemService} from '../../../services/order-item.service';

@Component({
    selector: 'product-gallery',
    templateUrl: './product-gallery.component.html',
    styleUrls: ['./product-gallery.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductGalleryComponent implements OnInit {

    @Input()
    public product: Product;

    @Input() order: Order;

    @Input() modalView = false;
    
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];

    constructor(private mobileCheck: MobileCheckServiceService, private orderItemService: OrderItemService) {
    }

    ngOnInit() {

        const moreImages = this.product.fullMedia.length > 1;

        // adjust size for mobile devices
        const screenWidth = this.mobile ? `${window.innerWidth - (15 * 2 + 20)}px` : '300px';
        const maxHeightWidth =  this.mobile ? screenWidth : moreImages ? '375px' : '300px';

        const modalGalleryOptions: NgxGalleryOptions[] =  [{
            preview: true,
            previewArrows: true,
            previewBullets: false,
            thumbnails: false,
            thumbnailsArrows: false,
            imageArrows: moreImages,
            imageBullets: moreImages,
            width: '275px',
            height: '275px',
            imageAnimation: NgxGalleryAnimation.Slide,
            imageSize: NgxGalleryImageSize.Contain
        },  {
            breakpoint: 1100,
            width: '230px',
            height: '230px',
            imagePercent: 80,
            preview: true,
            previewArrows: true,
            previewBullets: false,
            thumbnails: false,
            thumbnailsArrows: false,
            imageArrows: moreImages,
            imageBullets: moreImages,
            imageAnimation: NgxGalleryAnimation.Slide,
            imageSize: NgxGalleryImageSize.Contain
        }, {
            breakpoint: 600,
            width: '200px',
            height: '200px',
            imagePercent: 80,
            preview: true,
            previewArrows: true,
            previewBullets: false,
            thumbnails: false,
            thumbnailsArrows: false,
            imageArrows: moreImages,
            imageBullets: moreImages,
            imageAnimation: NgxGalleryAnimation.Slide,
            imageSize: NgxGalleryImageSize.Contain
        }, ];

        this.galleryOptions = this.modalView ?
            modalGalleryOptions
         : [
             {
                thumbnails: moreImages,
                imageArrows: moreImages,
                width: '510px',
                height: moreImages ? '680px' : '510px' ,
                thumbnailsColumns: this.product.fullMedia.length > 1  ? Math.min(this.product.fullMedia.length, 4) : 0,
                imageAnimation: NgxGalleryAnimation.Slide,
                imageSize: NgxGalleryImageSize.Contain
            },
            // max-width 800
            {
                breakpoint: 1100,
                width: '420px',
                height: moreImages ? '525px' : '420px',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20,
                thumbnails: moreImages && !this.mobile,
                imageArrows: moreImages,
            },
            // max-width 400
            {
                breakpoint: 900,
                width: '300px',
                height: moreImages ? '375px' : '300px',
                preview: false,
                thumbnails: moreImages && !this.mobile,
                imageArrows: moreImages,
                imageAnimation: NgxGalleryAnimation.Slide,
                imageSize: NgxGalleryImageSize.Contain
            },
            // max-width 567
            {
                breakpoint: 567,
                width: screenWidth,
                height: maxHeightWidth,
                preview: false,
                thumbnails: moreImages && !this.mobile,
                imageArrows: moreImages,
                imageAnimation: NgxGalleryAnimation.Slide,
                imageSize: NgxGalleryImageSize.Contain
            },

        ];

        // exclude thumbnails from gallery
        const productImages = this.product.fullMedia;
        this.galleryImages = [];

        for (const item of productImages) {
            const url: string = item['url'];

            let mediaType = 'image';
            if (url.includes('.mp4')) {
                mediaType = 'video/mp4';
            } else if (url.includes('.webm')) {
                mediaType =	'video/webm';
            } else if (url.includes('.ogg')) {
                mediaType = 'video/ogg';
            } else if (url.includes('.mov')) {
                mediaType = 'video/quicktime';
            }

            const isVideo = mediaType !== 'image';
            this.galleryImages.push({
                small: isVideo ?
                    '/assets/img/app/video-play-bttn-200x200.png' : url,
                medium: url,
                big: url,
                type: mediaType
            });
        }
    }

    get mobile(): boolean {
        return this.mobileCheck.mobile;
    }


    get isFeatured(): boolean {
        return false;
    }

    get isRecommended(): boolean {
        return this.orderItemService.isProductRecommended(this.product, this.order);
    }

    get isItemCut(): boolean {
        return this.product?.item_cut ? this.product.item_cut : false;
    }    
}
