import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';

import { SearchService } from '../../services/search.service';
import { Product } from '../../models/product.model';

const MIN_SYMBOLS_TO_SEARCH = 3;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  searchText = '';

  constructor(protected searchService: SearchService, private router: Router) {}

  ngOnInit(): void {
    this.setFocus();
    this.searchText = this.searchService.globalTerm || '';
  }

  selectSearchOption(product: Product): void {
    this.router.navigate([product.productURL]);
  }

  showHighlights(label: string): string {
    return this.searchService.highlight(label);
  }

  onSelectViewAll(): void {
    this.router.navigate(['/search-results']);
  }

  onClearSearch(): void {
    this.clearSearch();
  }

  onSearch(): void {
    this.search();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      this.search();
    }

    if (e.key === 'Escape') {
      this.clearSearch();
    }
  }

  get isSearching(): boolean {
    return this.searchService.searchingProducts;
  }

  get searchPerformed(): boolean {
    return this.searchService.searchPerformed;
  }

  get foundProducts(): Product[] {
    return this.searchService.foundProducts;
  }

  get foundProductsCount(): number {
    return this.foundProducts.length;
  }

  get isMoreDataAvailable(): boolean {
    return this.foundProductsCount < this.searchService.totalProducts;
  }

  private search(): void {
    if (!this.searchText) {
      this.searchService.clearSearchResults();
    }

    if (this.searchText.length < MIN_SYMBOLS_TO_SEARCH) {
      return;
    }

    this.searchService.globalTerm = this.searchText;
    this.searchService.productsSearch().pipe().subscribe();
  }

  private clearSearch(): void {
    this.searchText = '';
    this.searchService.clearSearchResults();
  }

  private setFocus(): void {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 500);
  }
}
