import {Component,  OnInit, ViewEncapsulation, OnDestroy} from '@angular/core';
import {takeWhile} from 'rxjs/operators';

import {LiveUpdateService} from '../../../../services/live-update.service';
import {formatCountdown} from '../../../../shared/helpers';


@Component({
    selector: 'app-header-update-info',
    templateUrl: './header-update-info.component.html',
    styleUrls: ['./header-update-info.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HeaderUpdateInfoComponent  implements OnInit, OnDestroy {
    private alive = true;
    countdownMessage = '';

    constructor(
        private updateService: LiveUpdateService,
    ) {}


    ngOnInit() {
        this.updateService.TimeToReload.pipe(takeWhile(() => this.alive))
          .subscribe( time => {
            this.countdownMessage = formatCountdown(time);
        })
    }


    ngOnDestroy() {
        this.alive = false;
    }

    reload() {
       this.updateService.reload();
    }

}
