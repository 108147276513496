import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import {AddressService} from '../../../services/address.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {Address} from '../../../models/address.model';
import {ContactDetails} from '../../../interfaces/contact-details';
import {Order} from '../../../models/order.model';
import {OrderItem} from '../../../models/order-item.model';
import {OrderItemDelivery} from '../../../models/order-item-delivery.model';
import {OrderItemService} from '../../../services/order-item.service';


@Component({
  selector: 'app-edit-contact-details-modal',
  templateUrl: './edit-contact-details-modal.component.html',
  styleUrls: ['./edit-contact-details-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditContactDetailsModalComponent implements OnInit {

  public order: Order;
  public orderItem: OrderItem;
  public orderItemDelivery: OrderItemDelivery;

  public address: Address;
  contactDetailsFormValid = true;
  inProgress  = false;
  contactDetailsUpdated = false;


  saveChangesInAddress = false;

  constructor(  private addressService: AddressService,
                private activeModal: NgbActiveModal,
                private orderItemService: OrderItemService) {
  }

  ngOnInit() {
    this.address = this.orderItemDelivery.getAddress();
  }

  close(order?: Order): void {
    this.activeModal.close(order);
  }

  get isCorporate(): boolean {
    return this.address.isCorporate;
  }

  handleOnContactDetailsChange(event: { isValid: boolean; contactDetails: ContactDetails[] }) {
    if (!event  || !event.isValid) {
      return;
    }

    this.contactDetailsUpdated = true;
    this.address.contact_details = event.contactDetails;
  }

  save() {
    if (!this.contactDetailsUpdated) {
      this.close();
    }

    this.inProgress = true;
    this.updateAddress(this.address).pipe(
        switchMap( retAddress => {
          if (!retAddress) {
            return of(false);
          }
          return this.updateOrder(retAddress);
        })
    ).subscribe(result => {
      this.inProgress = false;
      if (result) {
        this.close(this.order);
      }
    });
  }


  private updateAddress(address: Address): Observable<Address> {
    if (!this.saveChangesInAddress) {
      return of(address);
    }

    // no need to validate address here
    return this.addressService.storeContactDetails(address);
  }


  private updateOrder(address: Address): Observable<boolean> {
    if (!this.order || !this.orderItem || !this.orderItemDelivery) {
      return of(false);
    }
    const newOrderItem  =  cloneDeep(this.orderItem);
    const newOrderItemDelivery  =  cloneDeep(this.orderItemDelivery);
    newOrderItemDelivery.setAddress(address, null, {canOrder: true, isEmployee: true});

    newOrderItem.updateDelivery(newOrderItemDelivery);

    return this.orderItemService.saveOrderItem(this.order, newOrderItem).pipe(
        map(result => {
          this.order =  result.order;
          return result?.purchaseResult?.updated === true;
        })
    );
  }
}
