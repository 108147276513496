import {Component, ViewEncapsulation, HostListener, Input, AfterViewInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ScrollHelper} from '../../../shared/scrollHelper';

@Component({
  selector: 'app-back-top',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./back-top.component.scss'],
  template: `
    <i #backTop *ngIf="visible" class="fal fa-angle-up back-to-top transition" title="Back to Top" [@fadeInOut]></i>
  `,
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(500)),
    ])
  ]

})
export class BackTopComponent implements AfterViewInit {

  @Input() position: number = 400;
  @Input() showSpeed: number = 500;
  @Input() moveSpeed: number = 700;


  visible = false;

  ngAfterViewInit () {
    this._onWindowScroll();
  }

  @HostListener('click')
  _onClick(): boolean {
    ScrollHelper.scrollToTop();
    return false;
  }

  @HostListener('window:scroll')
  _onWindowScroll(): void {
    setTimeout(() => {
      this.visible = window.pageYOffset > this.position;
    })

  }

}
