import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { AuthService } from './services/auth.service';
import { TranslateService } from './services/translate.service';
import { ConfigService } from './services/config.service';
import { AppSettings } from './app.settings';
import {LocalStorageService, REDIRECT_POST_LOGIN} from './services/local-storage.service';
import { AddressService } from './services/address.service';
import { isInternetExplorer } from './shared/utils';
import { PaymentService } from './services/payments.service';
import {BudgetService} from './services/budget.service';
import {GoogleAnalytics} from './services/google-analytics.service';
import {MobileCheckServiceService} from './services/mobile-check-service.service';
import {User} from './models/user.model';
import { ClientCustomizationService } from './services/client-customization.service';

@Injectable()
export class AppInit {

    protected appSettings: AppSettings;
    protected authService: AuthService;
    protected configService: ConfigService;
    protected translateService: TranslateService;
    protected localStorageService: LocalStorageService;
    private addressService: AddressService;
    private paymentService: PaymentService;
    private budgetService: BudgetService;
    private googleService: GoogleAnalytics;
    private mobileCheckService: MobileCheckServiceService;
    private clientCustomizationService: ClientCustomizationService;

    constructor(private injector: Injector) {
    }

    init(): Promise<any> {
        this.appSettings = this.injector.get(AppSettings);
        this.authService = this.injector.get(AuthService);
        this.configService = this.injector.get(ConfigService);
        this.translateService = this.injector.get(TranslateService);
        this.localStorageService = this.injector.get(LocalStorageService);
        this.addressService = this.injector.get(AddressService);
        this.paymentService = this.injector.get(PaymentService);
        this.budgetService = this.injector.get(BudgetService);
        this.googleService = this.injector.get(GoogleAnalytics);
        this.mobileCheckService = this.injector.get(MobileCheckServiceService);
        this.clientCustomizationService = this.injector.get(ClientCustomizationService);

        // When we send the index from the server, we include client-level settings
        // that may be needed before webservices can be called

        // const appConfig = document.getElementById('app-config');
        // if (appConfig) {
        //     _.each(JSON.parse(appConfig.innerText), (val, key) => {
        //         if (this.appSettings.settings.hasOwnProperty(key)) {
        //             this.appSettings.settings[key] = (val === '') ? null : val;
        //         }
        //     });
        // }

        const router = this.injector.get(Router);

        // const token = document.cookie.match('(^|;)\\s*sso_user_token\\s*=\\s*([^;]+)');
        //
        // if (token) {
        //     this.localStorageService.setItem(U_TOKEN, token.pop());
        //     this.localStorageService.setItem(U_SSO, 'true');
        // } else {
        //     console.log('sso token is not found');
        // }

        this.mobileCheckService.check();

        return new Promise(async (resolve, reject) => {

            if (window.location.pathname.indexOf('/error') === 0) {
                resolve(true);
                return;
            }

            if (window.location.pathname.includes('/login')) {
                resolve(true);
                return;
            }

            if (isInternetExplorer()) {
                router.navigate(['/not-supported']);
                resolve(true);
                return;
            }

            // get user
            let user: User;
            try {
                user = await this.authService.validate().toPromise();

                if (!user) {
                    console.error('user validation is failed');
                    reject(false);
                    return;
                }
            } catch (e) {
                console.error('user validation is failed');
                reject(false);
                return;
            }
            this.googleService.submitUserId(user.id);

            const result  = await this.configService.fetchFeatures(user.id).toPromise();
            if (result !== true) {
                console.error('Failed to fetch user features')
                reject(false);
                return;
            }

            // set client specific favicon
            this.clientCustomizationService.setFavicon();

            // set  title
            this.clientCustomizationService.setTitle(this.configService.features.clientName + ' PnP');

            // get translations
            await this.translateService.getAll().toPromise();

            // fetch user addresses
            await this.addressService.fetchAllShippingAddresses();

            // get countries
            await this.configService.getCountries().toPromise();

            // get nav items
            // this.appSettings.settings.navigation = await this.configService.getNavItems().toPromise();

            // get credit card settings
            // await this.configService.fetchCreditCardSettings().toPromise();

            const location = this.localStorageService.getItem(REDIRECT_POST_LOGIN);

            // await this.paymentService.fetchFailedTransactions().toPromise();

            // await this.budgetService.get().toPromise();

            if (location) {
                this.localStorageService.removeItem(REDIRECT_POST_LOGIN);
                if (window.location.pathname === '/') {
                    router.navigate([location]);
                }
            }
            resolve(true);

        });
    }

    // init anything that is user specific that must be ready after login, before view init
    initPostLogin(): Promise<any> {
        return new Promise(async (resolve, reject) => {

            // fetch user addresses
            await this.addressService.fetchAllShippingAddresses();

            // get countries
            await this.configService.getCountries().toPromise();

            // get payment settings
            // await  this.configService.fetchCreditCardSettings().toPromise();

            // this.appSettings.settings.navigation = await this.configService.getNavItems().toPromise();

            resolve(true);
        });
    }
}
