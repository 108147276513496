import { Component, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionState } from '../../../constants/transaction-state';
import {TransactionGroup} from '../../../models/transaction-group.model';

@Component({
    selector: 'app-transation-result-modal',
    templateUrl: './transaction-result-modal.component.html',
    styleUrls: ['./transaction-result-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TransactionResultModalComponent {

    transactionGroup: TransactionGroup;

    constructor(private activeModal: NgbActiveModal) {}

    close(result = '') {
        this.activeModal.close(result);
    }



    displayCreditCardLabel(last_four): string {
        return `${last_four}`;
    }

    get isSuccessful(): boolean {
        return  this.transactionGroup.totals.state === TransactionState.SUCCESS;
    }

    startOver() {
        this.close('start_over');
    }
}

