<div class="d-flex align-items-center">
    <ng-container *ngIf="isDetailedView">
        <span>Recommended Quantity:&nbsp;</span>
        <div class="recommended-values p-1" [ngClass]="valueClass">
            {{ currentQty }}/{{ recommendedQty }}
        </div>
        <i class="fas fa-info-circle ml-2" placement="bottom" tooltipClass="tooltips"
           ngbTooltip="The Recommended Quantity, per unit, is a calculation based upon market needs per Wholesaler number"></i>
    </ng-container>
    <ng-container *ngIf="!isDetailedView">
        <div class="recommended-values p-1 font-6" [ngClass]="valueClass">
            {{ currentQty }}/{{ recommendedQty }}
        </div>
        <span class="font-6">&nbsp;Recommended Quantity <i class="fas fa-info-circle" placement="bottom" tooltipClass="tooltips"
                                                  ngbTooltip="The Recommended Quantity, per unit, is a calculation based upon market needs per Wholesaler number"></i>
        </span>
    </ng-container>
</div>
