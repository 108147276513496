import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import { Order } from '../../../models/order.model';
import { OrderService } from '../../../services/order.service';
import { AuthService } from '../../../services/auth.service';
import { OrderItem } from '../../../models/order-item.model';
import { OrderItemDelivery } from '../../../models/order-item-delivery.model';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {validateWbsForUnique, wbsIOSymbolsAllowed} from '../../../shared/helpers';
import {MultiTenantService} from '../../../services/multi-tenant.service';
import {ConfigService} from '../../../services/config.service';
import {hasClassName} from '@ng-bootstrap/ng-bootstrap/util/util';
import {EXTRA_INTERNAL_ORDER_LENGTH, INTERNAL_ORDER_LENGTH} from '../../../constants/globals';
import {Features} from '../../../interfaces/features';

@Component({
    selector: 'app-internal-order-modal',
    templateUrl: './internal-order.modal.component.html',
    styleUrls: ['./internal-order.modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InternalOrderModalComponent implements OnInit, OnDestroy {

    private alive = true;
    inProcess = false;

    order: Order;
    orderItem: OrderItem;
    orderItemDelivery: OrderItemDelivery;
    waitForUpdate = true;

    updatedOrder: Order;
    updatedOrderItem: OrderItem;
    updateOrderItemDelivery: OrderItemDelivery;

    constructor(private orderService: OrderService,
                private authService: AuthService,
                private activeModal: NgbActiveModal,
                private toastr: ToastrService,
                private multiTenantService: MultiTenantService,
                private configService: ConfigService) {
    }

    ngOnInit(): void {
        this.updatedOrder = new Order(this.order);
        this.updatedOrderItem = this.updatedOrder.createOrderItem(this.orderItem);
        this.updateOrderItemDelivery = new OrderItemDelivery(this.orderItemDelivery);
    }

    ngOnDestroy(): void {
        this.alive = false;
    }

    onInternalOrderChanged(newValue: string) {
        const value  =  !!newValue ? newValue.trim() : '';
        if (this.order.isWBSDefined) {
            this.updateOrderItemDelivery.wbs = value;
        } else {
            this.updateOrderItemDelivery.internal_order = value;
        }
        this.updatedOrderItem.updateDelivery(this.updateOrderItemDelivery, this.order.isOnDemand);
    }

    onInternalOrderReset() {
        this.updateOrderItemDelivery.wbs = '';
        this.updateOrderItemDelivery.internal_order = '';
        this.updatedOrderItem.updateDelivery(this.updateOrderItemDelivery, this.order.isOnDemand);
    }


    saveInternalOrder() {


        if (this.features.showInternalOrderCodes) {
            // validate length of wbd and internal order
            if (this.updateOrderItemDelivery.internalOrder.length > EXTRA_INTERNAL_ORDER_LENGTH) {
                this.toastr.warning(`Internal Order should not exceed ${EXTRA_INTERNAL_ORDER_LENGTH} symbols.`, 'Warning');
                return;
            }

        } else {
            if (this.features.showWBS) {

                if (this.updateOrderItemDelivery.quantity > 0 &&
                    (!this.updateOrderItemDelivery.internalOrder  && !this.order.internalOrder)) {
                    const warningMsg = `${this.internalOrderLabel} is required`;
                    this.toastr.warning(warningMsg, 'Warning');
                    return;
                }


                // validate length of wbd and internal order
                if (this.updateOrderItemDelivery.internalOrder && this.updateOrderItemDelivery.internalOrder !== '0') {
                    if (this.updateOrderItemDelivery.internalOrder.length > INTERNAL_ORDER_LENGTH) {
                        this.toastr.warning(`Internal Order should not exceed ${INTERNAL_ORDER_LENGTH} symbols.`, 'Warning');
                        return;
                    }
                    if (!wbsIOSymbolsAllowed(this.updateOrderItemDelivery.internalOrder)) {
                        const ioLabel = this.order?.isWBSDefined ? 'WBS' : 'Internal Order';
                        this.toastr.warning(`${ioLabel} allows  only digits, letters, /, #, -, . and space`);
                        return;
                    }
                }
            }
        }

        // check for both flags
        if (this.features.showInternalOrderCodes || this.configService.features.showWBS) {
            const uniqueData: string[] = [];
            const  getInternalOrder = (delivery: OrderItemDelivery): string => {
                // if delivery WBS is no defined, then order WBS is used
                return  delivery.internalOrder || this.updatedOrder.internalOrder
            }

            // not for deliveries with 0 qty
            const deliveries = this.updatedOrderItem.deliveries.filter(delivery => delivery.quantity > 0);
            for (const delivery of deliveries) {
                const uniqueKey = (delivery.uniqueAddressId || '') + '_' + getInternalOrder(delivery);
                if (!uniqueData.includes(uniqueKey)) {
                    uniqueData.push(uniqueKey);
                } else {
                    this.toastr.warning(
                      `You cannot have the same address and ${this.internalOrderLabel} for multiple deliveries`,
                      'Warning');
                    return;

                }
            }
        }


        this.inProcess = true;
        this.orderService.updateOrderItem(this.updatedOrder, this.updatedOrderItem)
          .subscribe(data => {
              this.inProcess = false;
              if (data) {
                  this.close({internalOrder: this.updateOrderItemDelivery.internalOrder});
              }
          });
    }

    close(next: any = null) {
        this.activeModal.close(next);
    }


    get internalOrderLabel(): string {
        if (this.order.isNotReal) {
            // fake order
            return 'Order Code';
        }
        if (this.features.showInternalOrderCodes) {
            return 'Internal Order';
        }

        return this.order.isWBSDefined ? 'WBS' : 'Internal Order';
    }

    get showInternalOrderCodes(): boolean {
        return this.configService.features.showInternalOrderCodes;
    }


    get features(): Features {
        return this.configService.features;
    }

    get interOrderMaxLength(): number {
        return INTERNAL_ORDER_LENGTH;
    }
}
