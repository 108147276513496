import {debounceTime, distinctUntilChanged, switchMap, takeWhile, tap} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';

import {AddressService} from '../../../../services/address.service';
import {DistributionList} from '../../../../models/distribution-list.model';

@Component({
  selector: 'app-distribution-list-select',
  templateUrl: './distribution-list-select.component.html',
  styleUrls: ['./distribution-list-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DistributionListSelectComponent implements OnInit, OnDestroy {

  selectedDistributionListId: number;
  private alive = true;
  distributionListSubject: Subject<string> = new Subject<string>();
  distributionListLoading = false;
  filteredDistributionLists: DistributionList[] = [];

  @Input()  distributionList: DistributionList;
  @Output() onDistributionListChanged = new EventEmitter<DistributionList>();

  constructor(private addressService: AddressService) {
  }

  ngOnInit() {
    this.selectedDistributionListId = this.distributionList?.id;
    this.filteredDistributionLists = [...this.allDistributionLists];

    this.distributionListSubject.pipe(
      takeWhile( () => this.alive),
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.distributionListLoading = true),
      switchMap(term => {
        if (!term) {
          return of(this.allDistributionLists);
        }

        const match = term.toLowerCase();
        return of(this.allDistributionLists.filter( list => list.label.toLowerCase().includes(match)));
      })
    ).subscribe( (dl: DistributionList[]) => {
      this.distributionListLoading = false;
      this.filteredDistributionLists = dl;
    });

  }

  onCloseDropdown() {
    // reset search items to default state
    this.filteredDistributionLists = [...this.allDistributionLists];
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onSelect(data) {
    if (data instanceof Event) {
      return;
    }
    this.onDistributionListChanged.emit(data);
  }


  get allDistributionLists(): DistributionList[] {
    return this.addressService.distributionLists;
  }
}
