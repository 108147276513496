/**
 * interface defines tracking numbers
 */
export interface TrackingNumber {
    carrier_name: string;
    number: string;
    status: string;
    url: string;
    mode?: string;
    shipping_date?: Date;
    quantity?: number;
}


// constant for unknown slug
export const UNKNOWN_SLUG = 'unknown';


