import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { hidePreloader } from '../../shared/utils';
// import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotFoundComponent implements AfterViewInit {
  // searchStore: any;
  // isSearching = false;
  // searchSuggestionsIsOpen = false;

  // constructor(private searchService: SearchService) {
  // }

  // ngOnInit() {
  // this.searchService.globalTerm = '';
  // }

  ngAfterViewInit(): void {
    hidePreloader();
  }

  // searchResult(): void {
  // this.router.navigate(['pages/search']);
  // }

  // showSearchSuggestions() {
  //   this.searchSuggestionsIsOpen = true;
  // }

  // hideSearchSuggestions() {
  //   this.searchSuggestionsIsOpen = false;
  // }
}
