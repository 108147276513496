<div *ngIf="_costCenter && sapForm">
    <p class="top">Enter individual codes, in their entirety. All field are require unless otherwise stated.</p>
    <form class="row" [formGroup]="sapForm" (ngSubmit)="verifySap()">
        <div class="col-12">
            <div class="input-group full-width">
                <label for="business_unity">Business Unit/Cost Center <span class="lowercase">(Required)</span>
                    <small class="text-danger ml-2" *ngIf="sapForm.get('business_unity').touched && sapForm.get('business_unity').hasError('required')">Business Unit is required</small>
                </label>
                <input [formControl]="businessUnity" type="text" name="business_unity" id="business_unity">
            </div>
        </div>

        <div class="col-12">
            <div class="input-group full-width">
                <label for="company_billing_entity">Company Billing Entity <span class="lowercase">(Required)</span>
                    <small class="text-danger ml-2" *ngIf="sapForm.get('company_billing_entity').touched && sapForm.get('company_billing_entity').hasError('required')">Company Billing Entity is required</small>
                </label>
                <input [formControl]="companyBullingEntity" type="text" name="company_billing_entity" id="company_billing_entity" readonly>
            </div>
        </div>


        <div class="col-12">
            <div class="input-group full-width">
                <label for="vendor_number">Vendor Number <span class="lowercase">(Required)</span>
                    <small class="text-danger ml-2" *ngIf="sapForm.get('vendor_number').touched && sapForm.get('vendor_number').hasError('required')">Vendor Number is required</small>
                </label>
                <input [formControl]="vendorNumber" type="text" name="vendor_number" id="vendor_number" readonly>
            </div>
        </div>

<!--        <div class="col-md-6 col-sm-12">-->
<!--            <div class="input-group full-width">-->
<!--                <label for="gl">Internal Order <span class="lowercase">(Required)</span>-->
<!--                    <small class="text-danger ml-2" *ngIf="sapForm.get('internal_order').touched && sapForm.get('internal_order').hasError('required')">Internal Order is required</small>-->
<!--                </label>-->
<!--                <input [formControl]="internal_order" type="text" name="internal_order" id="internal_order">-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="col-md-6 col-sm-12">-->
<!--            <div class="input-group full-width">-->
<!--                <label for="gl">WBS <span class="lowercase">(Required)</span>-->
<!--                    <small class="text-danger ml-2" *ngIf="sapForm.get('wbs').touched && sapForm.get('wbs').hasError('required')">WBS is required</small>-->
<!--                </label>-->
<!--                <input [formControl]="wbs" type="text" name="wbs" id="wbs">-->
<!--            </div>-->
<!--        </div>-->


<!--        <div class="col-md-6 col-sm-12">-->
<!--            <div class="input-group full-width">-->
<!--                <label for="gl">TAX data <span class="lowercase">(Required)</span>-->
<!--                    <small class="text-danger ml-2" *ngIf="sapForm.get('tax_data').touched && sapForm.get('tax_data').hasError('required')">TAX data is required</small>-->
<!--                </label>-->
<!--                <input [formControl]="tax_data" type="text" name="tax_data" id="tax_data">-->
<!--            </div>-->
<!--        </div>-->


<!--        <div class="col-md-6 col-sm-12">-->
<!--            <div class="input-group full-width">-->
<!--                <label for="gl">PSA Flag <span class="lowercase">(Required)</span>-->
<!--                    <small class="text-danger ml-2" *ngIf="sapForm.get('psa_flag').touched && sapForm.get('psa_flag').hasError('required')">PSA Flag is required</small>-->
<!--                </label>-->
<!--                <input [formControl]="psa_flag" type="text" name="psa_flag" id="psa_flag">-->
<!--            </div>-->
<!--        </div>-->

        <div class="col-12" *ngIf="invalidCostCenterMessage">
            <p class="text-danger text-center invalid-cost-center font-5 uppercase" >{{invalidCostCenterMessage}}</p>
        </div>

        <button *ngIf="!isVerified" [disabled]="!canVerify" class="btn btn-primary verify-sap btn-progress" type="submit" [ngClass]="{'performing' : inProgress}">
            <i class="fal fa-sync"></i>
            Submit
        </button>
    </form>
</div>
