import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

export class CustomNgbModal extends NgbModal {
  // we override this method, because it throws 
  // an error in console, the solution is 
  // to lose the focus of active button, since 
  // after modal open/close there added aria-hidden attr
  // https://stackoverflow.com/a/79210442/24598221
  override open(content: unknown, options?: NgbModalOptions): NgbModalRef {
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.blur();
    }

    return super.open(content, options);
  }
}
