import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from '../../../../models/product.model';
import { Order } from '../../../../models/order.model';
import { OrderItem } from '../../../../models/order-item.model';
import { ModalService } from '../../../../services/modal.service';
import {ProductService} from '../../../../services/product.service';
import {OrderItemService} from '../../../../services/order-item.service';
import {AddressService} from '../../../../services/address.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {MobileCheckServiceService} from '../../../../services/mobile-check-service.service';
import {PRODUCT_RESTRICTED, PRODUCT_REQUIRES_APPROVAL} from '../../../../constants/texts';

@Component({
    selector: 'app-product-order-item-header',
    templateUrl: 'product-order-item-header.component.html',
    styleUrls: ['./product-order-item-header.component.scss'],
})
export class ProductOrderItemHeaderComponent  {

    @Input() order: Order;

    @Input() orderItem: OrderItem;

    @Input() viewType: string;

    @Input() groupQuantity = -1;

    @Input() readonly = false;

    @Input() modal = false;

    @Input() showHideItemButton = false;

    @Input() showReorderButton = false; 

    @Output() editted = new EventEmitter<void>();

    @Output()  changedVisibility = new EventEmitter<boolean>();

    @Output() reorderItem = new EventEmitter();

    updatingFavorites = false;
    fetchingProduct = false;

    constructor( private modalService: ModalService, private productService: ProductService,
                 private orderItemService: OrderItemService,
                 private addressService: AddressService,
                 private router: Router,
                 private authService: AuthService,
                 private mobileCheck: MobileCheckServiceService) {
    }

    get showProductPrice(): boolean {
        return !this.product.hasVariations
    }

    get showMinOrder(): boolean {
        if (this.readonly) {
            return false;
        }

        if (!this.product.window_id) {
            return false;
        }

        if (!this.product.hasVariations) {
            return true;
        }

        return this.product.getGroupMinQuantity() > 0;
    }

    get showInStock(): boolean {
        return !this.readonly;
    }

    get product(): Product {        
        return this.orderItem.product;
    }

    get canShowVendor(): boolean {
        return false;
        // return this.viewType !== 'grid-item' && this.viewType !== 'detailed' && this.modal !== true;
    }

    public canBuyProduct(product: Product) {
        const orderItem = this.order.findOrderItemByProduct(product);
        if (orderItem) {
            return true;
        }

        if (product.inventoryType) {
            return product.inStock;
        }

        return true;
    }

    public get canShowFavorite(): boolean {
        return this.viewType === 'grid-item' && this.authService.canSeeFavorites;
    }

    public get hasFavorites(): boolean {
        return this.product && this.product.isFavorite;
    }

    toggleItemVisibility() {
        this.changedVisibility.emit(!this.orderItem.hidden);
    }


    get classes(): string {
        return `${this.viewType} ${this.orderItem.hidden === false ? 'line-separator--light-grey' : ''}`
    }

    get mobile(): boolean {
        return this.mobileCheck.mobile;
    }

    get mobileDevice(): boolean {
        return this.mobileCheck.mobileDevice;
    }

    showProduct(product: Product) {
        this.fetchingProduct = true;
        this.productService.getProductRedirectUrl(product.id).subscribe( url => {
            if (url) {
                this.router.navigateByUrl(url);
            }
            this.fetchingProduct = false;
        });
    }


    get productLink(): string {
        if (this.showReorderButton) {
        }

        return this.product.productURL;
    }

    showProductDetails() {
        if (this.showReorderButton) {
            // for reorder show product on modal
            this.reorderItem.emit()
        } else {
            this.router.navigate([this.product.productURL]);
        }
        return false;
    }

    get isProductRecommended(): boolean {
        return !this.readonly && this.orderItemService.isProductRecommended(this.product, this.order);
    }


    get isProductRestricted(): boolean {
        return this.product.restricted
    }

    get productRestrictedMessage(): string {
        return PRODUCT_RESTRICTED;
    }


    get productRequiresApprovalMessage(): string {
        return PRODUCT_REQUIRES_APPROVAL;
    }

    get isItemCut(): boolean {
        return this.orderItem?.item_cut ? this.orderItem.item_cut : false;
    }
}
