import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import {Address} from '../../../../models/address.model';
import {ContactDetails} from '../../../../interfaces/contact-details';
import {isEmptyArray} from '../../../../shared/utils';

const ADDITIONAL_CONTACTS_MAX_LIMIT = 10;
const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emptyMainContactDetails  = {
  contact: '',
  email: '',
  main: true,
};

const emptyContacts = [
  emptyMainContactDetails
];



function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const email = control.value;

    if (email) {
      if (!EMAIL_PATTERN.test(control.value)) {
        return {pattern: 'invalid'}
      }

      return null
    }

    return null;
  };
}


@Component({
  selector: 'app-edit-contact-details',
  templateUrl: './edit-contact-details.component.html',
  styleUrls: ['./edit-contact-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditContactDetailsComponent implements OnInit {
  numberOfAdditionalFields = ADDITIONAL_CONTACTS_MAX_LIMIT;

  contactForm = new UntypedFormGroup({
    contact_details: new FormArray([])
  });


  contact_details: FormArray;

  @Input() address: Address;

  @Output() onChange = new EventEmitter<{isValid: boolean, contactDetails: ContactDetails[]}>();

  ngOnInit(): void {
    if (isEmptyArray(this.address.contact_details)) {
      this.address.contact_details = emptyContacts;
    } else {
      const hasMainContacts = this.address.contact_details.find( c => c.main === true);
      if (!hasMainContacts) {
        this.address.contact_details = [emptyMainContactDetails, ...this.address.contact_details];
      }
    }


    this.contact_details = new FormArray([]);

    if (this.address && this.address.contact_details) {
      this.address.contact_details.forEach((item) => {
        this.contact_details.push(
            new FormGroup({
              contact: new UntypedFormControl(item.contact),
              email: new UntypedFormControl(item.email, [emailValidator()]),
              main: new UntypedFormControl(item.main)
            })
        );
      })
    }
  }


  onAddContactDetail(): void {
    if (this.contact_details.controls.length < 10) {
      this.contact_details.push(
          new FormGroup({
            contact: new UntypedFormControl(),
            email: new UntypedFormControl('', [emailValidator()]),
            main: new UntypedFormControl(false)
          })
      )
    }
  }

  onRemoveContactDetail(indexToRemove: number) {
    const contact: FormGroup  =  this.contact_details.controls[indexToRemove] as FormGroup;
    const isMain   =  contact.controls['main'].value === true;

    if (isMain) {
      // for main contract  just reset  input fields,do not remove them
      contact.controls['contact'].setValue('');
      contact.controls['email'].setValue('');
    } else {
      this.contact_details.removeAt(indexToRemove);
    }

    this.handleContactDetailsChange();

    return false;
  }

  validationClassName(control: AbstractControl): string {
    if (control.touched || control.dirty) {
      return control.invalid ? 'has-danger' : '';
    }
    return '';
  }

  handleContactDetailsChange() {
    // check if all form controls are valid
    const isValid = this.contact_details.controls.every(c => c.valid);
    // Extract and Trim Input Values
    let contactDetails: ContactDetails[] = this.contact_details.controls.map((item) => ({
      contact: item.get('contact')?.value?.trim() ?? '',
      email: item.get('email')?.value?.trim() ?? '',
      main: item.get('main')?.value ?? false
    }));

    // Filter contacts:
    contactDetails = contactDetails.filter(c => 
      c.contact !== '' || c.email !== '' // Keep rows where at least contact or email exists
    );

    // Validate: Only email without contact is NOT allowed
    const hasEmailWithoutContact = contactDetails.some(c => c.contact === '' && c.email !== '');
    console.log(hasEmailWithoutContact);

    if (!isValid || hasEmailWithoutContact) {
      this.onChange.emit({ isValid: false, contactDetails: [] });
      return;
    }

    this.onChange.emit({isValid: true, contactDetails});
  }

  onInputChange(event: Event) {
    // Get the new input value
    const newValue = (event.target as HTMLInputElement).value;
    // Perform actions based on the new value
    this.handleContactDetailsChange();
  }
}
