<ng-container *ngIf="readonly; else editTemplate">
    <div class="readonly-io--container font-weight-bold">{{ defaultInternalOrder }}</div>
</ng-container>

<ng-template #editTemplate>
    <ng-container *ngIf="!showInternalOrderCodes; else internalCodesTmpl">
        <ng-container *ngIf="!showWithModalDialog; else modalDialog">
            <app-clearable-input [defaultValue]="defaultInternalOrder"
                                 [value]="delivery.internalOrder"
                                 (valueChange)="onInternalOrderChanged($event)"
                                 [disabled]="disabled"
                                 (clear)="onInternalOrderReset()"></app-clearable-input>
        </ng-container>
    </ng-container>

</ng-template>


<ng-template #internalCodesTmpl>
  <div class="d-flex align-items-center gap-10" *ngIf="!inProcess">
      <ng-select
          #select
          class="ng-select--custom searchable codes--selector" [ngClass]="class"
          [items]="filteredCodes"
          placeholder="Internal Order Code"
          [searchable]="true"
          [typeahead]="searchSubject"
          (change)="onSelect($event)"
          [clearable]="false"
          dropdownPosition="bottom"
          [disabled]="disabled"
          [(ngModel)]="selectedCode">
          <ng-template ng-label-tmp let-item="item">
              <span class="d-block  font-5">{{item}}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <span class="font-6 codes--selector-label">
              {{ item }}
            </span>
          </ng-template>
      </ng-select>
      <div>
          <i *ngIf="selectedCode" class="fal fa-info-circle" placement="left" tooltipClass="tooltips"
             ngbTooltip="{{selectedCode}}"></i>

      </div>
  </div>
    <div *ngIf="inProcess">
        <span><i class="fal fa-sync rotate"></i></span>
    </div>
</ng-template>

<ng-template #modalDialog>
    <div class="d-flex gap-5">
        <div class="readonly-io--container no-width font-weight-bold">
            {{defaultInternalOrder}}
        </div>
        <button class="btn btn-icon" (click)="showIOEditModal()" [disabled]="isItemCut"><i class="fal fa-edit"></i></button>
    </div>
</ng-template>

