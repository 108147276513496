import { Injectable } from '@angular/core';
import { ConfigService } from '../services/config.service';

@Injectable({
    providedIn: 'root'
})
export class ClientCustomizationService {
    constructor(private configService: ConfigService) {}

    public setFavicon(url: string | null = null, size: string = null): void {
        url = this.configService.features?.favicon_url || `assets/img/icons/${this.configService.features.clientName?.toLowerCase() || 'demo'}/favicon.ico`;

        let link: HTMLLinkElement | null = document.querySelector(`link[rel*="icon"]${size ? `[sizes='${size}']` : ''}`);

        if (link) {
            link.href = url;
        } else {
            link = document.createElement('link');
            link.rel = 'icon';
            link.href = url;

            if (size) {
                link.setAttribute('sizes', size);
            }
            document.head.appendChild(link);
        }
    }

    public setTitle(customer: string): void {
        if (customer) {
            document.title = customer;
        }

    }
}
