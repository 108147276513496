<div class="">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">Enter Decision Point Quantity</h1>
        <button type="button"  class="btn btn-icon close" (click)="close()" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="productDecisionPointData.store_count > 0; else noneDP">
            <div class="mt-3 d-flex justify-content-center align-items-center">
                <span>Decision Point Name:</span>
                <div class="ml-3 border--light-grey p-2">
                    <span>{{orderDecisionPointData.label}}</span><br/>
                    <span>{{orderDecisionPointData.eligible_wslr_count}} Wholesalers, {{orderDecisionPointData.store_count}} Stores</span>
                </div>
            </div>

            <p class="text-center mt-3">This product is available to <span class="font-weight-bold">{{productDecisionPointData.eligible_wslr_count}}</span> Wholesalers that covers <span class="font-weight-bold">{{productDecisionPointData.store_count}}</span> stores.</p>

            <p class="text-center">The address used for each Wholesaler is the default address.</p>

            <p class="text-center font-weight-bold">Please enter the total number of items to order and we'll create the shipments for you.</p>

            <div class="d-flex justify-content-center">
                <input class="quantity" [(ngModel)]="storeCount" (change)="validateDPQuantity()"  min="0" digitOnly type="number" />
            </div>
        </ng-container>

        <div class="d-flex justify-content-center mt-3">
            <button type="button" class="btn btn-outline-primary mr-3" (click)="close()">Cancel</button>
            <button type="button" class="btn btn-primary float-right" (click)="submit()" [disabled]="!canSubmit">Create Shipments</button>
        </div>

        <div class="text-center mt-3">
            Contact your support team for assistance.
        </div>
    </div>
</div>

<ng-template #noneDP>
    <p class="mt-3 text-center">None of the Wholesalers assigned to the Decision Point have access to this product.</p>
</ng-template>
