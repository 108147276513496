import {BaseModel} from './base.model';
import {AppDate} from './date.model';
import {Attribute} from './attribute.model';
import {SKU} from './sku.model';
import {FilterItem} from './filter-item.model';
import {ProductMedia} from './product-media.model';
import {ProductAttribute} from './product-attribute.model';
import {OrderItem} from './order-item.model';

import * as _ from 'lodash';
import {Customization} from '../interfaces/customization';
import {GROUND, INTERNATIONAL, ProductView} from '../constants/product-type';
import {isEmptyArray, slugify} from '../shared/utils';
import { Currency } from '../interfaces/currency';
import {ShippingMethod} from './shipping-method';
import {replaceCurrentPathWithProductSlug} from '../shared/helpers';

export class Product extends BaseModel {
    public productView: ProductView;
    public id: number;
    public parent_id: number;
    public type: string;
    public slug: string;
    public label: string;
    public data: any;
    public ord: number;
    public created_at: AppDate;
    public updated_at: AppDate;
    public active: boolean;
    public favorite: boolean

    public attrs: Array<Attribute>;

    // derived properties
    public content: any;
    public media: Array<ProductMedia>;
    public skus: Array<SKU>;
    public attributes: Array<ProductAttribute>;
    public allow_upload: boolean;
    public brand: string;
    public product_group_id: number;
    public window_id: number;
    public in_stock: boolean;
    public has_inventory: boolean;
    public moq_met: boolean;
    public group_moq_met: boolean;
    public max_qty_met: boolean;

    public item_cut : boolean;
    public in_stock_slug: string;
    public orderItem: OrderItem;

    // we have to manually create these psuedo properties for the compiler
    public brandImage: string;
    public attr_is_featured: any;

    public in_market_start_at: AppDate;
    public in_market_end_at: AppDate;
    public ship_at: AppDate;

    public attr_budget: any;
    public attr_brand_code: any;
    public attr_coop_pct: string; // discount
    public specs: any;
    public attr_shipment_timing: string;

    private filters: Array<any>;

    public ow_purchasable;  // by default the product is purchasable

    private _productType: string;
    private _productCategory: string;
    private _productSubType: string;

    customization: Customization;
    shipping_options: any;

    _replacedProductId: number;

    public attr_capex_type: 'none' | 'alcohol' | 'non-alcohol';


    // short info
    public is_favorite: boolean;
    public is_recommended: boolean;
    public image_url: string;
    public is_featured: boolean;
    public program: string;
    public heavy_trade: boolean;
    public coop_pct: number
    public shipment_timing: string;
    public category: string;

    public recommended_qty_current: number;
    public recommended_qty_expected: number;
    public currency: Currency;

    public restricted: boolean;
    public need_approval: number;


    public shipping_methods: ShippingMethod[];

    constructor(data) {
        if (!data) {
            throw Error('invalid product data');
        }
        if (typeof data === 'string') {
            data = JSON.parse(data);
        }

        const productView = !!data.image_url ? ProductView.ShortInfo : ProductView.Full;

        if (productView === ProductView.Full) {
            super(data, {
                num: ['id', 'parent_id', 'ord', 'product_group_id', 'window_id'],
                bool: ['active', 'allow_upload', 'in_stock', 'ow_purchasable', 'has_inventory'],
                date: ['created_at', 'updated_at', 'in_market_start_at', 'in_market_end_at', 'ship_at']
            }, ['is_featured', 'budget', 'brand_code', 'coop_pct', 'capex_type', 'shipment_timing', 'currency', 'restricted', 'need_approval']);

        } else {
            super(data, {
                num: ['id', 'window_id'],
                bool: ['is_favorite', 'is_featured', 'has_inventory'],
            });
        }

        this.productView = productView;

        if (this.skus) {
            this.skus = this.skus.map( s => new SKU(s));
        } else {
            this.skus = [];
        }

        if (this.attributes) {
            this.attributes = this.attributes.map( a => new ProductAttribute(a));
        } else {
            this.attributes = [];
        }

        if (this.media) {
            this.media = this.media.map( m => new ProductMedia(m));
        } else {
            this.media = [];
        }

        if (!this.shipping_options) {
            this.shipping_options = {};
        }

        if (!this.attr_capex_type) {
            this.attr_capex_type = 'none';
        }

        if (typeof this.is_recommended === 'undefined') {
            this.is_recommended = false;
        }

        if (this.isBuyingWindow) {
            // this.is_featured = false;
            // this.attr_is_featured = 0;
        } else {
            this.is_recommended = false; // only for BW
        }

        if (!this.currency  && data.currency) {
            this.currency = data.currency;
        }

        if (!this.currency) {
            this.currency = null;
        }

        this.shipping_methods =  data.shipping_methods || [];
    }

    public get hasVariations() {
        return (this.skus?.length > 1);
    }

    public get inventoryType() {
        return (this.type === 'INVENTORY_CHILD');
    }

    public get inStock() {
        if (this.type === 'SKU_PRODUCT') {
            return true;
        }

        const sku = this.firstSku;

        if (!sku) {
            return false;
        }

        if (!this.hasVariations) {
            return this.firstSku.availableQuantity > 0;
        }

        return this.firstSku.combinedAvailableQuantity > 0;
    }

    public getSlug() {
        return `${this.id}-${slugify(this.label)}`;
    }

    public getUOM() {
        const pkg = this.skus[0].packageQuantity;
        return pkg > 1 ? 'PK' : 'EA';
    }

    public getBrand() {
        if (this.productView === ProductView.Full) {
            if (this.brand != null) {
                return this.brand;
            }


            const attrs = _.filter(this.attributes, {label: 'BRAND'});

            switch (attrs.length) {
                case 0:
                    this.brand = 'Generic';
                    break;
                case 1:
                    this.brand = attrs[0].options[0].label;
                    break;
                default:
                    this.brand = 'Multiple';
                    break;
            }

            return this.brand;
        }

        return this.brand;
    }


    public get productType(): string {
        if (!this._productType) {
            const attr  = this.attributes.find( a => a.label === 'ITEM_TYPE');
            if (attr && attr.options && attr.options.length > 0) {
                this._productType = attr.options[0].label;
            }
        }

        return this._productType || '';
    }

    public get productCategory(): string {
        if (this.productView === ProductView.Full) {
            if (!this._productCategory) {
                const attr  = this.attributes.find( a => a.label === 'CATEGORY');
                if (attr && attr.options && attr.options.length > 0) {
                    this._productCategory = attr.options[0].label;
                }
            }

            return this._productCategory || '';
        }

        return this.category;
    }

    public get productSubType(): string {
        if (!this._productSubType) {
            const attr  = this.attributes.find( a => a.label === 'ITEM_SUBTYPE');
            if (attr && attr.options && attr.options.length > 0) {
                this._productSubType = attr.options[0].label;
            }
        }

        return this._productSubType;
    }

    public getFilters() {
        if (this.filters != null) {
            return this.filters;
        }

        this.filters = [];

        _.each(this.attributes, (attr, i) => {
            this.filters[i] = new FilterItem({
                id: attr.options[0].value,
                attr_id: attr.label,
                ord: attr.ord,
                label: attr.options[0].label,
                selected: false,
                children: []
            });
        });

        return this.filters;
    }

    public getImages() {
        return _.filter(this.media, {type: 'IMAGE_MEDIA'});
    }

    public get mainImage() {
        const images = this.getImages();
        return (images && images.length > 0) ? images[0] : null;
    }

    public get mainImageUrl(): string {
        const img = this.mainImage;
        return img ? img.url : '/assets/img/app/no-image.jpg';
    }

    public getHeroImage() {
        const imgs = _.filter(this.media, {type: 'HERO_MEDIA'});

        return (imgs.length > 0) ? imgs[0] : null;
    }

    public getVideos() {
        return _.filter(this.media, {type: 'VIDEO_MEDIA'});
    }

    public getProgram() {
        if (this.productView === ProductView.Full) {
            const attrs = _.filter(this.attributes, {label: 'PROGRAM'});
            return (attrs && attrs.length > 0) ? attrs[0].options[0].label : null;
        }

        return this.program;
    }

    public getLowestPriceBracket() {
        return Object.keys(this.skus[0].prices)[0];
    }

    public getPriceTiers() {
        return (this.skus.length === 1) ? this.skus[0].getPriceTiers() : [];
    }

    public getBrandImage(brandImages) {
        const brandImage = brandImages.find((element: any) => {
            return element.label === this.getBrand();
        });

        if (brandImage) {
            return brandImage.thumb_url;
        } else {
            return false;
        }
    }

    public getSpecs() {
        const sku = this.skus[0];
        let specs = [];

        const fillSpecsByKeys = (keyLables: string[], attributes: ProductAttribute[]) => {
            const result = [];
            if (!isEmptyArray(attributes)) {
                keyLables.forEach( key => {
                    const attr = attributes.find( a => a.label === key);
                    if (attr) {
                        if ('CHANNEL' ===  key) {
                            // show all options for CHANNEL attribute
                            const values = attr.options.map( o => o.label);
                            result.push({key, value: values.join(', ')});
                        } else {
                            result.push({key, value: attr.options[0].label});
                        }

                    }
                })
            }

            return result;
        }

        specs = fillSpecsByKeys(['PROGRAM', 'BRAND', 'ITEM_TYPE', 'CHANNEL'], this.attributes);
        // specs.push({key: 'SKU_ID', value: sku.getDisplaySkuID()});  // sku should be hidden in details
        let keys = ['HEIGHT', 'WIDTH', 'DEPTH', 'LENGTH', 'DIAMETER', 'SUBSTRATE_TYPE', 'EST_DELIVERY', 'PKG_QTY', 'MIN_QTY',
            'REPLACES_ITEMS_TEXT', 'GROUP_MIN_QTY'];

        if (window.location.pathname.indexOf('on-demand') > -1) {
            keys.pop();
        }

        // FUSE-1052
        // Projected Delivery Date: Display for Non-Inventory Items Only
        if (this.inventoryType) {
            keys = keys.filter( key => key !== 'EST_DELIVERY');
        }

        // FUSE-663
        // keys = keys.concat(['COUNTRY_OF_ORIGIN','TARIFF_PCT']);

        _.each(keys, (key) => {
            let value = null;

            if (this.hasAttr(key.toLowerCase())) {
                value = this.getAttrValue(key.toLowerCase());

                if (value && value.toLowerCase() === 'N/A') { value = null }
            }
            if (sku.hasAttr(key.toLowerCase())) {
                value = this.getAttrValue(key.toLowerCase());

                if (value && value.toLowerCase() === 'N/A') { value = null }
            }

            if (value) {
              specs.push({key: key, value: value});
            }
        });

        if (!this.hasVariations) {
            // size and pack config are shown only product without variations, otherise it should use attributes for each sku
            specs = [...specs, ...fillSpecsByKeys(['SIZE', 'PACK_CONFIG'], this.attributes)];
        }

        return specs;
    }

    public geAutoShipSpecs() {
        const specs = [];
        const sku = this.skus[0];
        const keys = ['PRINTED_SIDES', 'TOTAL_PRINT_COLORS'];

        keys.forEach( key => {
            let value = null;

            if (this.hasAttr(key.toLowerCase())) {
                value = this.getAttrValue(key.toLowerCase());

                if (value && value.toLowerCase() === 'N/A') { value = null }
            }

            if (sku.hasAttr(key.toLowerCase())) {
                value = this.getAttrValue(key.toLowerCase());

                if (value && value.toLowerCase() === 'N/A') { value = null }
            }

            if (value) {
                specs.push({key: key, value: value});
            }
        });


        if (sku) {
            specs.push({key: 'Carton Factor', value: sku.attr_pkg_qty});
        } else {
            specs.push({key: 'Carton Factor', value: 'Unk'});
        }


        return specs;
    }

    public getProductSize(sku?: SKU): string {
        return this.getAttValueByName('SIZE', sku);
    }

    public getProductPackageConfig(sku?: SKU): string {
        return this.getAttValueByName('PACK_CONFIG', sku);
    }


    public getSKUbyID(id: number) {
        if (this.skus.length === 1) {
            return this.skus[0];
        }

        return this.skus.find( s => s.id === id);
    }

    public get coopPct(): number {
        if (this.productView === ProductView.Full) {
            if (!this.attr_coop_pct) {
                return 0;
            }
            if (typeof this.attr_coop_pct === 'string') {
                return  parseFloat(this.attr_coop_pct);
            }
            return this.attr_coop_pct;
        }

        return this.coop_pct;
    }

    get discount(): number {
        const coopPct = this.coopPct;
        if (!this.isHeavyTrade && coopPct) {
            return coopPct;
        }
        return undefined;
    }

    get isHeavyTrade(): boolean {
        if (this.productView === ProductView.Full) {
            if (this.attributes) {
                const attr = this.attributes.find( a => a.label === 'HEAVY_TRADE');

                if (attr && attr.options.find(opt => opt.label.toLowerCase() === 'yes')) {
                    return true;
                }
            }

            return false;

        }
        return this.heavy_trade;
    }

    // returns aggregated_quantity of this first sku in product has no variations,
    // otherwise group_aggregated_quantity
    // get aggregatedQuantity(): number {
    //     if (!this.skus || this.skus.length === 0) {
    //         return 0;
    //     }
    //     const aq = (this.hasVariations ? this.skus[0].group_aggregated_quantity : this.skus[0].aggregated_quantity) || 0;
    //     return Math.max(aq, 0);
    // }

    // getPrice(quantity: number, sku: SKU = null): number {
    //     if (sku) {
    //         return sku.getPrice(quantity);
    //     }
    //
    //     if (isEmptyArray(this.skus)) {
    //         return 0;
    //     }
    //
    //     return this.skus[0].getPrice(quantity);
    // }

    getPrice(sku?: SKU) {
        if (!sku) {
            if (isEmptyArray(this.skus)) {
                return 0;
            }
            // get price from the first SKU
            return this.firstSku.getPrice();
        }
        return sku.getPrice();
    }


    get firstSku(): SKU {
        return (this.skus && this.skus.length > 0) ? this.skus[0] : null;
    }

    public getMaxQuantity(s?: SKU): number {
        if (s) {
            return s.maxQuantity;
        }
        if (isEmptyArray(this.skus)) {
            return 0;
        }

        let combinedAvailableQuantity = 0;
        if (this.hasVariations) {
            if (s) {
                combinedAvailableQuantity = s.combined_available_quantity || 0;
            } else {
                combinedAvailableQuantity = this.firstSku.combined_available_quantity || 0;
            }
        }

        const maxQuantity = this.skus.reduce( (total, skuItem) => total + skuItem.maxQuantity, 0);

        return  combinedAvailableQuantity > 0 ? Math.min(combinedAvailableQuantity, maxQuantity) : maxQuantity;
    }

    public getMinQuantity(s?: SKU): number {
        if (s) {
            return s.minQuantity;
        }
        if (isEmptyArray(this.skus)) {
            return 0;
        }

        return this.skus.reduce( (total, skuItem) => total + skuItem.minQuantity, 0)
    }

    public getGroupMinQuantity(s?: SKU): number {
        // if (this.hasVariations) {
        //     return this.getMinQuantity(s);
        // }

        if (isEmptyArray(this.skus)) {
            return 0;
        }

        return this.skus[0].groupMinQTY;
    }


    public getAvailableQuantity(s?: SKU): number {
        if (s) {
            return s.availableQuantity;
        }

        if (isEmptyArray(this.skus)) {
            return 0;
        }

        return this.skus.reduce( (total, skuItem) => total + skuItem.availableQuantity, 0)

    }


    public getAggregatedQuantity(s?: SKU): number {
        if (s) {
            return s.aggregatedQuantity;
        }

        if (isEmptyArray(this.skus)) {
            return 0;
        }

        return  this.skus.reduce( (total, skuItem) => total + skuItem.aggregatedQuantity, 0);
    }

    public getGroupAggregatedQuantity(s?: SKU): number {
        if (this.hasVariations) {
            return this.getAggregatedQuantity();
        }
        if (isEmptyArray(this.skus)) {
            return -1;
        }

        return this.skus[0].group_aggregated_quantity;

    }

    public get isCustomizable(): boolean {
        return !!this.customization;
    }


    public get isOnDemand(): boolean {
        return !this.window_id;
    }

    public get isBuyingWindow(): boolean {
        return this.window_id > 0;
    }
    public get productURL(): string {
        const currentPath = window.location.pathname;
        const path  = replaceCurrentPathWithProductSlug(currentPath, this.getSlug());
        if (!!path) {
            return path;
        }

        if (this.isOnDemand) {
            return `/on-demand/products/${this.getSlug()}`;
        }
        // buying windows
        return `/buying-window/${this.window_id}/products/${this.getSlug()}`;
    }


    public get rootPath(): string {
        return this.window_id > 0 ? `/buying-window/${this.window_id}/products` : `/on-demand/products`;
    }

    public getProductUrlForOrderWindow(orderWindowId: number): string {
        let url = this.productURL;
        if (this.isBuyingWindow && orderWindowId) {
            url = `/buying-window/${orderWindowId}/products/${this.getSlug()}`
        }
        return url;
    }
    public get firstSkuId(): string {
        const sku = this.firstSku;
        if (sku) {
            return sku.getDisplaySkuID();
        }
        return '';
    }


    public get isInternationalShipmentAllowed(): boolean {
        return this.shipping_options[INTERNATIONAL] !== -1;
    }

    public get replacedProductId(): number {
        if (this._replacedProductId === undefined) {
            const attr = this.attrs.find( a => a.attr === 'replaced_by');
            this._replacedProductId =  attr ? (+attr.value) : 0;
        }

        return this._replacedProductId;
    }
    public get isFavorite(): boolean {
        if (this.productView === ProductView.Full) {
            return this.favorite === true;
        }
        return this.is_favorite;
    }

    public setFavorite(val: boolean) {
        if (this.productView === ProductView.Full) {
            this.favorite = val;
        } else {
            this.is_favorite = val;
        }
    }

    public toggleFavorite() {
        this.setFavorite(!this.isFavorite);
    }

    // get min/max prices range for variations
    public get priceVariations(): {min?: number, max?: number, originalMin?: number, originalMax?: number} {

        let skuWithMinPrice = this.skus[0];
        let skuWithMaxPrice = this.skus[0];
        // const quantity = this.variationAggregatedQuantity || 1;
        for (const sku of this.skus) {
            if (sku.getPrice() < skuWithMinPrice.getPrice()) {
                skuWithMinPrice = sku;
            }
            if (sku.getPrice() > skuWithMaxPrice.getPrice()) {
                skuWithMaxPrice = sku;
            }
        }

        const maxPrice = skuWithMaxPrice.getPrice();
        const minPrice = skuWithMinPrice.getPrice();

        if (maxPrice > minPrice) {
            return {
                min: minPrice,
                originalMin: skuWithMinPrice.originalPrice,
                max: maxPrice,
                originalMax: skuWithMaxPrice.originalPrice
            };
        }

        return {
            min: minPrice,
            originalMin: skuWithMinPrice.originalPrice,
        };
    }



    public get variationAggregatedQuantity(): number {
        if (!this.hasVariations) {
            return 0;
        }

        if (this.skus[0].group_aggregated_quantity > 0) {
            return this.skus[0].group_aggregated_quantity;
        }

        return this.skus.reduce( (total, skuItem) => total + skuItem.aggregatedQuantity, 0);
    }


    // list of media except thumbnails
    public get fullMedia(): ProductMedia[] {
        return this.media.filter( media => media.type !== 'THUMB_MEDIA')
    }

    public get thumbnail(): string {
        if (this.productView === ProductView.Full) {
            const thumbMedia = this.media.find(response => response.type === 'THUMB_MEDIA') ||
                this.media.find(response => response.type === 'IMAGE_MEDIA');
            return thumbMedia?.url || '';
        }

        return this.imageUrl;
    }

    public get productActive(): boolean {
        return this.active && this.inStock;
    }


    public get sportTeamCode(): string {
        return this.getAttrValue('sports_team_code') || '';
    }

    public get  budgetCodes(): string[] {
        const codes = [];
        if (this.attr_budget) {
            codes.push(this.attr_budget);
        }

        if (this.isHeavyTrade && this.attr_brand_code) {
            codes.push(this.attr_brand_code);
        }
        return codes;
    }

    // public get firstAvailableShippingMethod(): string {
    //     for (const [key, value] of Object.entries(this.shipping_options)) {
    //         if (value !== -1) {
    //             return key;
    //         }
    //     }
    //     return GROUND; // if no shipment method are defined - first one is ground
    // }

    public get inStockQuantity(): number {
        const sku = this.firstSku;
        if (!sku) {
            return 0;
        }

        if (this.hasVariations) {
            return sku.inStockCombinedQuantity;
        }

        return sku.inStockQuantity;
    }

    public get combinedAvailableQuantity(): number {
        const sku = this.firstSku;
        if (!sku) {
            return 0;
        }

        if (this.hasVariations) {
            return sku.combinedAvailableQuantity;
        }

        return 0;
    }


    public get variationsMaxQuantity(): number {
        if (!this.hasVariations) {
            return 0;
        }

        if (this.combinedAvailableQuantity > 0) {
            return this.combinedAvailableQuantity;
        }

        return  this.skus.reduce((total, skuItem) => total + skuItem.maxQuantity, 0);
    }

    public get pendingQuantity(): number {
        const sku = this.firstSku;
        if (!sku) {
            return 0;
        }

        if (this.hasVariations) {
            return (sku.combinedAvailableQuantity < sku.combinedPendingQuantity) ?
                sku.combinedAvailableQuantity : sku.combinedPendingQuantity;
        }

        return (sku.availableQuantity < sku.pendingQuantity) ?
            sku.availableQuantity : sku.pendingQuantity;

    }

    private getAttValueByName(name: string, sku?: SKU): string {
        if (!name) {
            return '';
        }

        const lowerCaseName = name.toLowerCase();
        if (sku) {
            return sku.getAttrValue(lowerCaseName) || '';
        }

        const attribute = this.attributes.find( a => a.label.toLowerCase() === lowerCaseName);
        if (attribute) {
            return attribute.options[0].label;
        }

        if (this.hasAttr(lowerCaseName)) {
            return this.getAttrValue(lowerCaseName);
        }


        if (!isEmptyArray(this.skus)) {
            const firstSKU = this.skus[0];
            if (firstSKU.hasAttr(lowerCaseName)) {
                return firstSKU.getAttrValue(lowerCaseName);
            }
        }

        return '';

    }

    public get isFeatured(): boolean {
        if (this.productView === ProductView.Full) {
            return +this.attr_is_featured > 0;
        }
        return  this.is_featured;
    }


    public get relatedProductIds(): number[] {
        const  val = this.getAttrValue('related_items');
        if (val) {
            return  val.split(',').map( v => +(v.trim()));
        }
        return [];
    }

    public getVendor(sku?: SKU): string {
        const vendor = sku ? sku.vendor : this.firstSku?.vendor;
        return vendor || '';
    }

    public get hasLease(): boolean {
        return this.attr_capex_type === 'alcohol' || this.attr_capex_type === 'non-alcohol';
    }

    public get shippingTiming(): string {
        return this.attr_shipment_timing || this.shipment_timing || '';
    }

    public get imageUrl(): string {
        return this.image_url || '/assets/img/app/no-image.jpg';
    }

    public getOriginalPrice(sku?: SKU): number {
        const fromSKU = sku || this.firstSku;
        return fromSKU?.originalPrice || 0;
    }

    public get isApprovalRequired(): boolean {
        return this.need_approval === 1;
    }

    public get hasPricingTiers(): boolean {
        if (!this.firstSku) {
            return false;
        }
        return this.firstSku.hasPricingTiers;
    }


    public get hasPriceRunOnRange(): boolean {
        if (!this.firstSku) {
            return false;
        }
        return this.firstSku.hasPriceRunOnRange;
    }


    public findFirstAvailableShippingMethod(countryCode: string): string {
        if (!countryCode) {
            return '';
        }
        if (isEmptyArray(this.shipping_methods)) {
            return '';
        }

        const methods = this.shipping_methods.filter( m => m.country === countryCode);
        if (isEmptyArray(methods)) {
            return '';
        }

        return methods[0].method;
    }

    public get minQuantity(): number {
        if (isEmptyArray(this.skus)) {
            return 0;
        }

        if (this.hasVariations) {
            return this.firstSku.groupMinQTY;
        }

        return this.firstSku.minQuantity;

    }

    public get hasMinQuantity(): boolean {
        return this.minQuantity > 1;
    }

    public get maxQuantity(): number {
        if (isEmptyArray(this.skus)) {
            return 0;
        }

        return this.firstSku.maxQuantity;
    }

    public get hasMaxQuantity(): boolean {
        return this.maxQuantity > 0;
    }


    public get  availableQuantity(): number {
        if (isEmptyArray(this.skus)) {
            return 0;
        }

        if (!this.has_inventory) {
            return 0;
        }

        if (this.hasVariations) {
            return this.firstSku.combinedAvailableQuantity;
        }

        return this.firstSku.availableQuantity
    }

    public get hasAvailableQuantity(): boolean {
        return this.availableQuantity > 0;
    }

    public get computedMaxQuantity(): number {
        const availableQty  = this.availableQuantity;
        const maxQty = this.maxQuantity;
        let quantity  = 0;
        if (availableQty > 0) {
            if (maxQty > 0) {
                quantity = Math.min(availableQty, maxQty);
            } else {
                quantity = availableQty;
            }
        } else {
            quantity = maxQty;
        }
        return quantity;
    }
}
