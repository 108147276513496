import {ChangeDetectionStrategy, Component, ViewEncapsulation, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {OrderItemDelivery} from '../../../../../models/order-item-delivery.model';
import {TrackingNumber, UNKNOWN_SLUG} from '../../../../../interfaces/tracking-number';
import * as ORDER_STATES from '../../../../../constants/order-states';
import * as TRACKING_STATES from '../../../../../constants/tracking-states';

@Component({
    selector: 'app-delivery-tracking',
    templateUrl: './delivery-tracking.component.html',
    styleUrls: ['./delivery-tracking.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryTrackingComponent {

    trackingNumbers: TrackingNumber[] = [];
    status: string;
    trackingData: string;
    show = 'text';

    @Input() vendor = '';

    @Input() orderState: string;

    @Input() set delivery(val: OrderItemDelivery) {
        if (!val) {
            return;
        }
        this.initTrackingData(val);
    }

    constructor(private ngbModalService: NgbModal) {
    }


    private initTrackingData(delivery: OrderItemDelivery) {
        this.trackingNumbers = delivery.normalizedTrackingNumbers;

        if (this.trackingNumbers.length === 0) {
            // tracking data not available
            this.trackingData = 'N/A';
            this.status = (this.orderState === ORDER_STATES.COMPLETE_STATE) ? TRACKING_STATES.DELIVERED : TRACKING_STATES.PENDING;
        } else if (this.trackingNumbers.length === 1) {
            const trackingNumber = this.trackingNumbers[0];

            // single tracking number case
            this.status = trackingNumber.carrier_name !== UNKNOWN_SLUG ? trackingNumber.status :
                (this.orderState === ORDER_STATES.COMPLETE_STATE) ? TRACKING_STATES.DELIVERED : TRACKING_STATES.PENDING;
            this.show = 'link';
            if (this.validateTrackingNumber(this.trackingNumbers[0].number)) {
                this.show = 'vendor';
                this.trackingData = this.trackingNumbers[0].number;
            }
        } else {
            // multiple tracking case

            // find global tracking status
            this.status = (this.trackingNumbers.filter(
                t => t.status === TRACKING_STATES.DELIVERED && t.carrier_name !== UNKNOWN_SLUG).length === this.trackingNumbers.length) ?
                TRACKING_STATES.DELIVERED : TRACKING_STATES.IN_TRANSIT;
            this.show = 'modal';

            this.trackingNumbers.forEach(tracking => {
                tracking.mode =  this.validateTrackingNumber(tracking.number) ? 'vendor' : 'link';
            });
        }

    }


    validateTrackingNumber(value: string): boolean {
        return (value === 'MISC' || value === 'Call Client Service');
    }

    showDetails(content) {
        this.ngbModalService.open(content).result.then(() => {
        }, () => {
        });
    }


    isDelivered(val): boolean {
        return val === TRACKING_STATES.DELIVERED;
    }

}
