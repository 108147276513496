import {BaseModel} from './base.model';
import {AppDate} from './date.model';
import {Address} from './address.model';
import {isEmptyArray} from '../shared/utils';

export class DistributionList extends BaseModel {
    public id: number;
    public label: string;
    public created_at: AppDate;
    public updated_at: AppDate;

    public addresses: Address[];

    constructor(data) {
        super(data, {
            num: ['id', ],
            date: ['created_at', 'updated_at']
        });

        if (!this.addresses) {
            this.addresses = [];
        }
    }


    public get addressCount(): number {
        return this.addresses.length;
    }

    public get hasAddresses(): boolean {
        return !isEmptyArray(this.addresses);
    }

    public containsAddress(address: Address): boolean {
        if (!this.hasAddresses) {
            return false;
        }

        return this.addresses.some((a) => a.uniqueAddressId === address.uniqueAddressId);
    }

}
