// readonly contact details dialog for corporate address only

import { Component, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ContactDetails} from '../../../interfaces/contact-details';

@Component({
  selector: 'app-contact-details--info-modal',
  templateUrl: './contact-details-info-modal.component.html',
  styleUrls: ['./contact-details-info-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactDetailsInfoModalComponent {
  public contactDetails: ContactDetails[];

  constructor(private activeModal: NgbActiveModal) {
  }

  close(): void {
    this.activeModal.close();
  }
}
