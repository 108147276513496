<ng-container *ngIf="visible">
    <div class="backdrop" (click)="onClosed()"></div>
    <div class="uppercase mobile__menu" >
      <div class="menu__header font-weight-bold" ><a routerLink="/notifications"  (click)="onClosed()" class="link">Notifications</a></div>

        <ng-container *ngIf="canSeeBuyingWindows">
            <div class="menu__header font-weight-bold" [ngClass]="{'container-disabled': isUserRestricted}" (click)="toggleBuyingWindowMenu()">{{orderWindowLabel}}
                <i class="fas fa-angle-up"
                   [ngClass]="{'fa-angle-up' : buyingWindowMenuExpanded, 'fa-angle-down': !buyingWindowMenuExpanded}"></i>
            </div>
            <div class="menu__item" [ngClass]="{'visible' : buyingWindowMenuExpanded}"><a href="#" class="link" (click)="bwProgramsClicked()">Programs</a></div>
            <div class="menu__item" [ngClass]="{'visible' : buyingWindowMenuExpanded}"><a href="#" class="link" (click)="bwProductsClicked()">All Products</a></div>
        </ng-container>
        <ng-container *ngIf="canOrder">
            <div class="menu__header font-weight-bold" [ngClass]="{'container-disabled': isUserRestricted}" (click)="toggleOnDemandMenu()">{{onDemandLabel}}
                <i class="fas fa-angle-up"
                   [ngClass]="{'fa-angle-up' : onDemandMenuExpanded, 'fa-angle-down': !onDemandMenuExpanded}">
                </i>
            </div>
            <div class="menu__item" [ngClass]="{'visible' : onDemandMenuExpanded}"><a href="#" class="link" (click)="odProgramsClicked()">Programs</a></div>
            <div class="menu__item" [ngClass]="{'visible' : onDemandMenuExpanded}"><a href="#" class="link" (click)="odProductsClicked()">All Products</a></div>
        </ng-container>

        <div class="menu__header font-weight-bold" (click)="accountMenuExpanded = !accountMenuExpanded">Account
            <i class="fas"
               [ngClass]="{'fa-angle-up' : accountMenuExpanded, 'fa-angle-down': !accountMenuExpanded}">
            </i>
        </div>
        <div class="menu__item" [ngClass]="{'visible' : accountMenuExpanded}"><a routerLink="/account" (click)="onClosed()" class="link">Profile</a></div>
        <div *ngIf="canSeeAddresses" class="menu__item" [ngClass]="{'visible' : accountMenuExpanded}"><a routerLink="/account/addresses"  (click)="onClosed()" class="link">Address Book</a></div>
        <div *ngIf="canShowPaymentPage"  class="menu__item" [ngClass]="{'visible' : accountMenuExpanded}"><a routerLink="/account/payment-methods" (click)="onClosed()" class="link">Payment Methods</a></div>
        <div *ngIf="canShowTransaction"  class="menu__item" [ngClass]="{'visible' : accountMenuExpanded}"><a routerLink="/account/transactions" (click)="onClosed()" class="link">Credit Transactions</a></div>
        <div *ngIf="canShowBudgets" class="menu__item" [ngClass]="{'visible' : accountMenuExpanded}"><a routerLink="/account/budgets" (click)="onClosed()" class="link">Budgets</a></div>
        <div *ngIf="canSeeOrders" class="menu__item" [ngClass]="{'visible' : accountMenuExpanded}"><a routerLink="/account/orders" (click)="onClosed()" class="link">Order History</a></div>
        <div *ngIf="canShowAutoshipPage" class="menu__item" [ngClass]="{'visible' : accountMenuExpanded}"><a routerLink="/account/auto-ship" (click)="onClosed()" class="link">Auto-ship</a></div>
        <div *ngIf="false" class="menu__item" [ngClass]="{'visible' : accountMenuExpanded}"><a routerLink="/account/news" (click)="onClosed()" class="link">News</a></div>
        <div *ngIf="canSeeRepairRequests" class="menu__item" [ngClass]="{'visible' : accountMenuExpanded}"><a routerLink="/account/repair-requests" (click)="onClosed()" class="link">Repair Request</a></div>

        <div class="menu__header font-weight-bold" (click)="supportMenuExpanded = !supportMenuExpanded">Support
            <i class="fas fa-angle-up"
               [ngClass]="{'fa-angle-up' : supportMenuExpanded, 'fa-angle-down': !supportMenuExpanded}">
            </i>
        </div>
        <!--<div class="menu__item" [ngClass]="{'visible' : supportMenuExpanded}"><a href="mailto:HelpNest@hhglobal.com" target="_blank" (click)="onClosed()" class="link">Contact Support</a></div>-->
        <!--<div class="menu__item" [ngClass]="{'visible' : supportMenuExpanded}"><a routerLink="/faq" (click)="onClosed()" class="link">Frequently Asked Questions</a></div>-->
        <div class="menu__item" [ngClass]="{'visible' : supportMenuExpanded}">
            <app-user-guide></app-user-guide>
<!--            <ng-container *ngIf="userGuides.length > 1; else userGuide">-->
<!--                <div class="menu__header font-weight-bold" (click)="supportSubMenuExpanded = !supportSubMenuExpanded">-->
<!--                    User Guide-->
<!--                    <i class="fas fa-angle-up"-->
<!--                        [ngClass]="{'fa-angle-up' : supportSubMenuExpanded, 'fa-angle-down': !supportSubMenuExpanded}">-->
<!--                    </i>-->
<!--                </div>-->
<!--                <div class="menu__item" [ngClass]="{'visible' : supportSubMenuExpanded}" *ngFor="let item of userGuides">-->
<!--                    <a [href]="item.url" target="_blank" download (click)="onClosed()">{{ item.label }}</a>-->
<!--                </div>-->
<!--            </ng-container>-->
<!--            <ng-template #userGuide>-->
<!--&lt;!&ndash;                <a [href]="userGuides[0].url" target="_blank" download>{{userGuides[0].label}}</a>&ndash;&gt;-->
<!--            </ng-template>-->
        </div>
    </div>
</ng-container>
