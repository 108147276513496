import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from 'dayjs';

import {AppDate} from '../models/date.model';
import { DATE_FORMAT } from '../constants/date-format';

export const US_LONG_FORMAT = DATE_FORMAT.US_LONG_FORMAT;
export const US_SHORT_FORMAT = DATE_FORMAT.US_SHORT_FORMAT;


@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    constructor() {
    }

    transform(key: any, args?): string {
        let dayjsDate;
        let format = US_LONG_FORMAT;

        if (typeof key === 'string') {
            dayjsDate = dayjs(new Date(key));
        } else if (typeof key === 'number') {
            dayjsDate = dayjs(key);
        } else if (key instanceof Date) {
            dayjsDate = dayjs(key);
        } else if (key instanceof AppDate) {
            dayjsDate = key.date;

            if (args && args.indexOf(' formatAsLocal') > -1) {
                dayjsDate.utc();
                args = args.replace(' formatAsLocal', '');
            }
        } else if (key instanceof dayjs) {
            dayjsDate = key;
        }

        if (args && args === 'us-long') {
            format = US_LONG_FORMAT;
        } else if (args && args === 'us-short') {
            format = US_SHORT_FORMAT;
        } else if (args) {
            format = args;
        }
        return (dayjsDate && dayjsDate.isValid()) ? dayjsDate.format(format ? format : 'DD-MMM-YYYY') : '';
    }
}
