<ng-template #modalTemplate>
    <div *ngIf="showHeader" class="modal-header {{ headerClass }}">
        <h1 id="modal-label" class="full-width text-align-center"><i *ngIf="headerIcon" class="fal {{ headerIcon }}"></i>{{ title }}</h1>
        <button type="button" (click)="hide()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <ng-content select=".app-modal-body"></ng-content>
    </div>
    <div *ngIf="showFooter" class="modal-footer">
        <ng-content select=".app-modal-footer"></ng-content>
    </div>
</ng-template>
