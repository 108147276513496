import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {catchError, finalize, map, switchMap, tap} from 'rxjs/operators';
import {action, computed, observable} from 'mobx';
import {ToastrService} from 'ngx-toastr';
import {Observable, of, Subject} from 'rxjs';

import {BaseService} from './base.service';
import {AppSettings} from '../app.settings';
import {Response} from '../models/response.model';
import {Address} from '../models/address.model';
import {isEmptyArray, updateItemInArray} from '../shared/utils';
import {AuthService} from './auth.service';
import {sortAddresses, sortDistributionLists} from '../shared/helpers';
import {API_URL} from '../constants/api-urls';
import {Order} from '../models/order.model';
import {PresetAddress} from '../interfaces/preset-address';
import {AddressCategory, AddressType} from '../enums/address-type';
import {DistributionList} from '../models/distribution-list.model';

const MAX_SEARCH_LIMIT = 500;
const AMOUNT_ADDRESS_TO_SHOW = 10;
const MAX_SEARCH_SYMBOLS = 500;

export interface AddressResult {
    is_valid: boolean,
    exists: boolean,
    addresses: Address[],
    savedAddress: Address
}


export interface AddressInput {
    match?: string,
    label?: string; // company name
    city?: string; //  city/town
    state?: string; //  # state/county
    country?: string; //  country
    zip_code?: string; //  postal code
    address_type?: number;
    product_id?: number;
    excludeDisabled?: boolean;
    excludeNotValid?: boolean;
}


export interface SearchAddressResult {
    userAddresses: Address[],
    corporateAddresses: Address[]
    distributionLists: DistributionList[]
    corporateAddressesTotalCount: number;
    userAddressesTotalCount: number;
    distributionListsTotalCount: number;
}


@Injectable()
export class AddressService extends BaseService {

    @observable personalAddresses: Address[] = [];
    @observable corporateAddresses: Address[] = [];
    distributionLists: DistributionList[] = [];

    @observable updatingAddressId = null;

    distributionListChanged = new Subject<DistributionList | number>();


    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        private authService: AuthService
    ) {
        super('/account/addresses', http, appSettings, toastr);
    }

    /**
     * requested once -  right after user is logged in
     * promise is require to ensure addresses are loaded
     */
    fetchAllShippingAddresses(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (!this.authService.canSeeAddresses) {
                resolve();
                return;
            }

            this.fetchAllAddresses().subscribe(() => {
                resolve();
            });
        });
    }

    private fetchAllAddresses(): Observable<boolean> {

        return this.searchAddresses({excludeNotValid: false, excludeDisabled: true}).pipe(
            map(result => {
                if (result) {
                    this.setPersonalAddresses(sortAddresses(result.userAddresses));
                    this.setCorporateAddresses(sortAddresses(result.corporateAddresses));
                    this.setDistributionLists(sortDistributionLists(result.distributionLists));
                    return true;
                }
                return false;
            }));

    }

    public searchAddressesByTerm(input: AddressInput): Observable<Address[]> {
        return this.searchAddresses({...input, excludeDisabled: true, excludeNotValid: false}).pipe(
            map(result => {
                if (!result) {
                    return [];
                }
                const retValue  = [ ...sortAddresses(result.corporateAddresses), ...sortAddresses(result.userAddresses)];
                return sortAddresses(retValue);
            })
        )
    }


    public searchAddresses(input: AddressInput): Observable<SearchAddressResult> {
        const url = `${API_URL}/search-address`;
        const headersBody = {'Range': `${MAX_SEARCH_LIMIT}-0`};

        const  inputParams  = {...input};
        delete inputParams.excludeNotValid;
        delete inputParams.excludeDisabled;

        // limit text by 500 characters
        if (inputParams.match && inputParams.match.length > MAX_SEARCH_SYMBOLS) {
            inputParams.match = inputParams.match.substring(0, MAX_SEARCH_SYMBOLS);
        }

        return this.http.post<HttpResponse<Response>>(url, inputParams  , {observe: 'response', headers: headersBody}).pipe(
            map(httpResponse => {
                const response = new Response(httpResponse.body);

                const addressedCount: number = +httpResponse.headers.get('x-count-addresses');
                const locationsCount: number = +httpResponse.headers.get('x-count-locations');
                let distributionListsCount = 0;
                let addresses: Address[] = [];
                let entities: Address[] = [];
                let distributionLists: DistributionList[] = [];

                if (!isEmptyArray(response.data)) {

                    // split  addresses by type and handle disabled ones
                    addresses = response.data.filter(a => a.address_type === AddressCategory.Personal)
                      .map(a => new Address(a));
                    entities = response.data.filter(a => a.address_type === AddressCategory.Corporate)
                      .map(a => new Address(a));
                    distributionLists = response.data.filter(a => a.address_type as AddressCategory === AddressCategory.DistributionList)
                      .map(a => this.transformDistributionListFromResponse(a, addresses, entities));
                    distributionListsCount = distributionLists?.length || 0;

                    if (input.excludeDisabled) {
                        // count disabled  addresses
                        addresses = addresses.filter(a => a.active === true);
                        entities = entities.filter(a => a.active === true);
                    }

                    if (input.excludeNotValid) {
                        addresses = addresses.filter(a => a.is_valid === true);
                    }

                }
                return {
                    corporateAddressesTotalCount: locationsCount,
                    userAddressesTotalCount: addressedCount,
                    distributionListsTotalCount: distributionListsCount,
                    userAddresses: addresses,
                    corporateAddresses: entities,
                    distributionLists
                };
            }),
            catchError(this.handleError('AddressService::searchAddresses', null))
        )
    }

    @action public setDefault(address: Address, wslrIds: number[] = []): Observable<boolean> {

        const requestBody = {
            ...address
        };

        this.updatingAddressId = address.id;
        const url = `${API_URL}/account/addresses/${address.id}`;
        return this.http.put<Response>(url, requestBody,
            {headers: this.xsrfTokenHeader})
            .pipe(
                // switchMap(() => this.fetchAllAddresses()),
                tap((result) => {
                    if (result) {
                        this.toastr.success('Default address has been changed');
                    }
                }),
                catchError(this.handleError('AddressService::setDefault', null)),
                finalize(() => this.updatingAddressId = null)
            );
    }

    save(address: Address): Observable<AddressResult> {
        // always validate address before save
        return this.validate(address).pipe(
            switchMap(result => {
                if (!result) {
                    // validation failed
                    return of(null);
                }

                if (!result.is_valid) {
                    // address is not valid
                    return of(result);
                }

                // check if returned address is the same
                if (result.addresses.length === 1 && address.isTheSame(result.addresses[0]) !== true) {
                    // returned address is not the same -- show to user
                    return of(result);
                }

                // store validated result
                return this.store(address).pipe(
                    map(savedAddress => {
                        // we should keep address_type
                        savedAddress.address_type = address.address_type;
                        savedAddress.type = 'OTHER_ADDR';
                        result.savedAddress = savedAddress;
                        return result;
                    })
                );
            })
        );
    }

    public storeContactDetails(address: Address): Observable<Address> {
        // get personal address
        if (!address) {
            return of(null);
        }

        const personalAddress  = this.personalAddresses.find(a => a.id === address.id);
        if (!personalAddress) {
            return of(null);
        }

        personalAddress.contact_details = address.contact_details;
        return this.store(personalAddress);
    }

    public store(address: Address, hideToastMessage = false): Observable<Address> {
        let request = null;
        if (!address) {
            return of(null);
        }

        const url = `${API_URL}/account/addresses`;

        // // only user addresses are supported
        // address.address_type = 1; // user address
        // address.type = 'OTHER_ADDR';
        // address.is_valid = true;

        // save address
        if (!address.id) {
            request = this.http.post<Response>(url, address, {headers: this.xsrfTokenHeader});
        } else {
            request = this.http.put<Response>(`${url}/${address.id}`, address, {headers: this.xsrfTokenHeader});
        }

        return request.pipe(
            map((response: Response) => {
                if (response && response.data) {
                    return new Address(response.data);
                } else {
                    return null;
                }
            }),
            catchError(this.customHandleError('AddressService::save', null)),
            tap((result: Address) => {
                if (result) {
                    if (result.address_type === AddressType.Personal) {
                        const updatedAddresses  = updateItemInArray<Address>(this.personalAddresses, result);
                        const newAddressList = sortAddresses(updatedAddresses);
                        this.setPersonalAddresses(newAddressList);
                    } else {
                        const updatedAddresses  = updateItemInArray<Address>(this.corporateAddresses, result);
                        const newAddressList = sortAddresses(updatedAddresses);
                        this.setCorporateAddresses(newAddressList);
                    }

                    // update  address in distribution lists
                    this.updateAddressInDistributionList(result);

                    if (!hideToastMessage) {
                        this.toastr.success('Your address has been saved');
                    }

                }
            })
        );

    }

    customHandleError<T>(
        operation = 'operation',
        result?: T,
        options: { hideResponseError?: boolean; customErrorText?: string } = {}
    ): (error: any) => Observable<T> {
        const inputFieldsLabel: Record<string, string> = {
            label: 'Company Name / Address line 1',
            street_1: 'Street Address',
            street_2: 'Suite/Unit',
            city: 'City',
            state: 'State / Province / Region',
            zip_code: 'Zip / Postal Code',
            phone: 'Phone Number'
        };

        const lengthErrorMessages: Record<string, string> = {
            label: 'Value exceeds character limit of 250',
            street_1: 'Value exceeds character limit of 250',
            street_2: 'Value exceeds character limit of 250',
            city: 'Value exceeds character limit of 250',
            state: 'Value exceeds character limit of 250',
            zip_code: 'Value exceeds character limit of 250',
            phone: 'Value exceeds character limit of 25',
        };

        return (error: any): Observable<T> => {
            // // Add custom behavior before calling the base class implementation
            // console.log('Custom behavior: Logging error in derived class', error);
            // Check if the error contains a list of validation errors and log each one
            if (error.error.errors && Array.isArray(error.error.errors)) {
                error.error.errors.forEach((err: { property: string; message: string }) => {
                    const fieldLabel = inputFieldsLabel[err.property] || err.property;
                    let lengthErrorMsg = err.message;
                    if (err.message.includes('too long')) {
                        lengthErrorMsg = lengthErrorMessages[err.property] || err.message;
                    }

                    const errorMessage = `<strong>${fieldLabel}</strong>: ${lengthErrorMsg}`;
                    this.toastr.error(errorMessage, '', {enableHtml: true});
                });

            } else if (error.error) {
                if (error.error.error.includes('already exists')) {
                    // show only warning for already existing address
                    this.toastr.warning(error.error.error);
                } else {
                    this.toastr.error(error.error.error, '', {enableHtml: true});
                }
            } else {
                this.handleError(operation, result, options);
            }
            return of(result as T);
        };
    }


    private validate(address: Address): Observable<AddressResult> {
        if (!address) {
            return of(null);
        }

        if (address.is_valid) {
            // it's already valid - no need to validate again
            return of({
                is_valid: true,
                exists: false,
                addresses: [],
                savedAddress: null
            });
        }

        const validatedAddress = {
            country_code: address.country_code,
            state: address.state,
            zip_code: address.zip_code,
            city: address.city,
            street_1: address.street_1,
            street_2: address.street_2 || ''
        }

        // send address id for already exist address
        if (address.id) {
            validatedAddress['id'] = address.id;
        }

        const url = `${API_URL}/account/addresses/validate`;

        return this.http.post<Response>(url, validatedAddress).pipe(
            map(response => {
                if (response && response.data) {
                    let exists = false;
                    let valid = false;
                    let addresses: Address[] = [];

                    if (response.data.existing_address) {
                        exists = true;
                        addresses = [new Address({...response.data.existing_address, wslr_ids: address.wslr_ids})];
                    } else {
                        valid = response.data.is_valid;
                        if (!response.data.is_valid && !response.data.addresses.length) {
                            const errorMsg = response.data.error_message || 'No valid addresses found. Please update and try again.';
                            this.toastr.error(errorMsg);
                        }
                        addresses = Array.isArray(response.data.addresses) ?
                            response.data.addresses.map(a => new Address({
                                ...a,
                                label: address.label,
                                is_valid: true,
                                wslr_ids: address.wslr_ids
                            })) : [];
                    }

                    for (let i = 0; i < addresses.length; i++) {
                        addresses[i].client_address_id = address.client_address_id;
                    }

                    return {
                        is_valid: valid,
                        exists,
                        addresses,
                        saved: false
                    }
                }
                return null;
            }),
            catchError(this.customHandleError('AddressService::save', null))
        );
    }



    private fetchAddressesByIds(ids: PresetAddress[]): Observable<Address[]> {
        if (isEmptyArray(ids)) {
            return of([]);
        }
        const url = `${API_URL}/account/addresses/for-ids`;
        return this.http.post<Response>(url, ids).pipe(
            map(response => {
                if (!isEmptyArray(response.data)) {
                    return response.data.map(item => new Address({...item, isWSLR: this.authService.isEmployee}));
                }
                return [];
            }),
            catchError(this.handleError('AddressService::fetchAddressesByIds', []))
        )
    }

    public getPresetAddressesForOrder(order: Order): Observable<Address[]> {
        if (!order) {
            return of([]);
        }

        if (isEmptyArray(order.attr_preset_addresses)) {
            return of([]);
        }

        // can be only distribution list or preset addresses
        if (order.distributionList) {
            return of([]);
        }

        // user addresses don't have entity id
        const userAddressesIds = this.personalAddresses.map(a => a.id);
        const missingAddresses = order.attr_preset_addresses.filter(i => {
            if (!!i.entity_id) {
                return true;
            }
            return !userAddressesIds.includes(i.id);
        });

        return this.fetchAddressesByIds(missingAddresses).pipe(
            map(retAddresses => {
                const result: Address[] = [];

                order.attr_preset_addresses.forEach(a => {
                    if (!a.entity_id) {
                        // user address use case
                        let address = this.personalAddresses.find(i => i.id === a.id);
                        if (!address) {
                            // look up in returned addresses
                            address = retAddresses.find(i => (i.id === a.id && i.entity_id === undefined));
                        }

                        if (!!address) {
                            result.push(address);
                        }
                    } else {
                        // location use case
                        const address = retAddresses.find(i => (i.id === a.id && i.entity_id === a.entity_id));
                        if (!!address) {
                            result.push(address);
                        }
                    }
                })

                return result;
            })
        );
    }

    @computed
    public get allShipmentAddresses(): Address[] {

        const filteredPersonalAddresses = sortAddresses(this.personalAddresses.filter(a => (a.canUseAsShipment))) ;
        const filteredCorporateAddresses = sortAddresses(this.corporateAddresses.filter(a => (a.canUseAsShipment)));
        return [...filteredCorporateAddresses.slice(0, AMOUNT_ADDRESS_TO_SHOW),
            ...filteredPersonalAddresses.slice(0, AMOUNT_ADDRESS_TO_SHOW)];
    }

    // just for backward compatibility
    @computed
    public get addresses(): Address[] {
        return this.allShipmentAddresses;
    }

    @action
    private setPersonalAddresses(addresses: Address[]) {
        if (isEmptyArray(addresses)) {
            this.personalAddresses = [];
        } else {
            this.personalAddresses = addresses;
        }

    }

    @action setCorporateAddresses(addresses: Address[]) {
        if (isEmptyArray(addresses)) {
            this.corporateAddresses = [];
        } else {
            this.corporateAddresses = addresses;
        }
    }

    private setDistributionLists(distributionLists: DistributionList[]) {
        if (isEmptyArray(distributionLists)) {
            this.distributionLists = [];
        } else {
            this.distributionLists = [...distributionLists];
        }
    }


    private findAddressById(addresses: Address[], {id, addressType}: {id: number, addressType: AddressType}): Address | undefined {
        if (addressType === AddressType.Personal) {
            if (!isEmptyArray(this.personalAddresses)) {
                return this.personalAddresses.find(a => a.id === id);
            }
            return addresses.find(a => a.id === id);
        }

        if (addressType === AddressType.Corporate) {
            if (!isEmptyArray(this.corporateAddresses)) {
                return this.corporateAddresses.find(a => a.id === id);
            }
            return addresses.find(a => a.id === id);
        }

        return;
    }

    public saveDistributionList(dl: DistributionList | number): Observable<DistributionList | boolean> {
        if (!dl) {
            return of(false);
        }

        if (typeof dl === 'number') {
            // delete request
            return this.deleteDistributionList(dl);
        }


        if (!dl.id) {
            // create new distribution list
            return this.createDistributionList(dl);
        }

        return this.updateDistributionList(dl);
    }

    public findDistributionListById(id: number): DistributionList | undefined {
        if (!id) {
            return;
        }
        return this.distributionLists.find(dl => dl.id === id);
    }


    private createDistributionList(dl: DistributionList): Observable<DistributionList> {
        const url = `${API_URL}/account/distribution-list`;
        const body = this.transformDistributionListToRequest(dl);


        return this.http.post<Response>(url, body).pipe(
          map(response => {
              if (!response.data) {
                  return null;
              }
              return this.transformDistributionListFromResponse(response.data, this.personalAddresses, this.corporateAddresses);
          }),
          tap(result => {
              if (result) {
                  const updatedDistributionLists = updateItemInArray<DistributionList>(this.distributionLists, result);
                  this.setDistributionLists(updatedDistributionLists);
              }
          }),
          catchError(this.handleError('AddressService::createDistributionList', null))
        )
    }


    private updateDistributionList(dl: DistributionList): Observable<DistributionList> {
        const url = `${API_URL}/account/distribution-list/${dl.id}`;
        const body = this.transformDistributionListToRequest(dl);

        return this.http.put<Response>(url, body).pipe(
          map(response => {
              if (!response.data) {
                  return null;
              }
              return this.transformDistributionListFromResponse(response.data, this.personalAddresses, this.corporateAddresses);
          }),
          tap(result => {
              if (result) {
                  const updatedDistributionLists = updateItemInArray<DistributionList>(this.distributionLists, result);
                  this.setDistributionLists(updatedDistributionLists);

                  this.distributionListChanged.next(result);
              }
          }),
          catchError(this.handleError('AddressService::updateDistributionList', null))
        )


    }


    private deleteDistributionList(dlId: number): Observable<boolean> {
        const url = `${API_URL}/account/distribution-list/${dlId}`;
        return this.http.delete<Response>(url).pipe(
          map(response => {
              return !!response.data;
          }),
          tap(result => {
              if (result) {
                  const updatedDistributionLists = updateItemInArray<DistributionList>(this.distributionLists, dlId);
                  this.setDistributionLists(updatedDistributionLists);
                  this.distributionListChanged.next(dlId);
              }
          }),
          catchError(this.handleError('AddressService::deleteDistributionList', false))
        )


    }

    public fetchDistributionListForOrders(dl: DistributionList): Observable<number[]> {
        const url = `${API_URL}/account/distribution-list/${dl.id}/orders`;

        return this.http.get<Response>(url).pipe(
            map(response => {
                if (!response.data) {
                    return null;
                }
                return response.data as number[];
            }),
            catchError(this.handleError('AddressService::testDistributionListForOrders', []))
        )
    }

    public testDistributionListForOrders(dl: DistributionList): Observable<boolean> {
        return this.fetchDistributionListForOrders(dl).pipe(
            map( result => {
                return !isEmptyArray(result);
            })
        )
    }



    private transformDistributionListFromResponse(response,
                                                  personalAddresses: Address[] = [],
                                                  corporateAddresses: Address[] = []): DistributionList {
        if (!response) {
            return null;
        }
        const {id, label, addresses} = response;
        let dlAddresses: Address[] = [];
        // get addresses
        if (!isEmptyArray(addresses)) {
            dlAddresses  = addresses.map(a => {
                if (a.address_type === AddressType.Personal) {
                    return this.findAddressById(personalAddresses, {id: a.id, addressType: AddressType.Personal});
                }
                return this.findAddressById(corporateAddresses, {id: a.id, addressType: AddressType.Corporate});
            })


            // filter undefined values if any
            dlAddresses = dlAddresses.filter(a => a !== undefined);
        }

        return new DistributionList({id, label, addresses: dlAddresses});
    }

    private transformDistributionListToRequest(dl: DistributionList)  {
        const result = {
            label: dl.label,
            addresses: !isEmptyArray(dl.addresses) ?
              dl.addresses.map(a => ({id: a.id, address_type: a.address_type})) : []
        }

        if (dl.id) {
            result['id'] = dl.id;
        }

        return result;
    }


    private  updateAddressInDistributionList(address: Address) {
        // should go through all distribution lists and update address
        const  dlHasAddress = (dl: DistributionList): boolean => {
            if (isEmptyArray(dl.addresses)) {
                return false;
            }
            const uniqueAddressId = address.uniqueAddressId;
            return dl.addresses.some(a => a.uniqueAddressId === uniqueAddressId);
        }

        for ( const dl of this.distributionLists) {
            if (dlHasAddress(dl)) {
                dl.addresses = updateItemInArray(dl.addresses, address);
                this.distributionLists = updateItemInArray(this.distributionLists, dl);
                this.distributionListChanged.next(dl);
            }
        }
    }
}


