<ng-container *mobxAutorun>
    <ng-container *ngTemplateOutlet="mobile ? mobileView : desktopView"></ng-container>
</ng-container>

<ng-template #desktopView>
    <div class="app-cart-select" *ngIf="visible">
        <ng-container *ngIf='hasOrders'>
          <strong>{{ label }}: </strong>
          <ng-select
            class="order-select font-5"
            placeholder="Select an Order to Make Active"
            [items]="orders"
            bindValue="id"
            [searchable]="false"
            [clearable]="false"
            (change)="selectOrder($event)"
            name="order"
            [(ngModel)]="selectedID">
            <ng-template ng-label-tmp let-item="item">
              {{ getOrderLabel(item) }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{ getOrderLabel(item) }}
            </ng-template>
          </ng-select>
        </ng-container>
        <button *ngIf="allowAdd" (click)="addOrder()" type="button" class="btn btn-outline-primary">{{ buttonLabel }}</button>
    </div>
</ng-template>
<ng-template #mobileView>
    <div class="d-flex align-items-center" *ngIf="visible">
        <ng-container *ngIf='hasOrders'>
          <ng-select
            class="order-select font-5 mobile"
            placeholder="Select an Order to Make Active"
            [items]="orders"
            bindValue="id"
            [searchable]="false"
            [clearable]="false"
            (change)="selectOrder($event)"
            name="order"
            [(ngModel)]="selectedID">
            <ng-template ng-label-tmp let-item="item">
              {{ getOrderLabel(item) }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{ getOrderLabel(item) }}
            </ng-template>
          </ng-select>
        </ng-container>
        <button *ngIf="allowAdd" (click)="addOrder()" type="button" class="btn btn-outline-primary ml-3">CREATE</button>
    </div>
</ng-template>

