<div class='address--item--container'>
    <div class='select--container'>
        <ng-select
            class="distribution-list--container"
            [items]="filteredDistributionLists"
            bindValue="id"
            placeholder="Select list"
            [searchable]="true"
            [loading]="distributionListLoading"
            [typeahead]="distributionListSubject"
            (change)="onSelect($event)"
            [clearable]="false"
            (close)="onCloseDropdown()"
            dropdownPosition="bottom"
            [(ngModel)]="selectedDistributionListId">
            <ng-template ng-label-tmp let-item="item">
                <span class="d-block font-weight-bold font-5">{{ item.label }}</span>
                <span>{{ item.addressCount }} Addresses</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <span class="font-weight-bold font-6">
                    {{ item.label }}</span><br/>
                <span class="font-6">{{ item.addressCount }} Addresses</span>
            </ng-template>
        </ng-select>
    </div>
</div>


