import {interval, Observable, of, Subject} from 'rxjs';
import {catchError, switchMap, takeWhile} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';

const VERSIONING_FILE_URL = 'ui.txt';
const POOLING_INTERVAL = 1000 * 60 * 5;  // 5 minutes
const RELOAD_TIMEOUT = 60 * 10;  // 10 minutes
const UPDATE_COUNT_TIME = 1000; // every second

@Injectable()
export class LiveUpdateService  implements OnDestroy {
  private alive = true;
  private isOn = true;  // can be turned off permanently
  private currentVersion: string;  // contains  version loaded from ui.txt
  private active: boolean;  //  false if ui.txt is not found
  private updateFound  = false;
  private countdown = RELOAD_TIMEOUT;

  public UpdateAvailable = new Subject<boolean>();
  public TimeToReload = new Subject<number>();

  constructor(private http: HttpClient) { }

  public initializeLiveUpdater() {
    if (!this.isOn) {
      return;
    }
    console.log('Initializing live update service');
    this.fetchVersionFile().subscribe( version => {
      if (version)  {
        this.currentVersion = version;
        console.log(`Actual version: ${this.currentVersion}`);
        this.active = true;
        // start version checker
        this.versionChecker();
      } else {
        this.active = false;
        console.log('Version file not found. Live update service is turned off.');
      }
    });
  }


  private versionChecker() {
    if (!this.isOn || !this.active) {
      // version check is turned off or version file is not found
      return;
    }

    interval(POOLING_INTERVAL).pipe(
      takeWhile(() => this.active && this.alive  && !this.updateFound),
      switchMap(() => this.fetchVersionFile())
    ).subscribe( version => {
      if (!version || version === this.currentVersion) {
        // no update found
        return;
      }
      this.updateFound = true; // stop checking for updates
      this.UpdateAvailable.next(true);
      this.startCountdown();
    });
  }

  private fetchVersionFile(): Observable<string> {
    const path = `/${VERSIONING_FILE_URL}`;
    return this.http.get(path, {responseType: 'text'}).pipe(
      catchError((error) => {
        console.error('Error fetching version file', error);
        return of('');
      })
    );
  }


  private  startCountdown() {
    interval(UPDATE_COUNT_TIME).pipe(
      takeWhile(() => this.alive)
    ).subscribe(() => {
      if (this.countdown > 0 ) {
        this.countdown--;
        this.TimeToReload.next(this.countdown);
      }

      if (this.countdown === 0) {
        this.reload();
      }
    });
  }

  public reload() {
    window.location.reload();
  }


  ngOnDestroy() {
    this.alive = false;
  }
}
