import { Currency } from '../interfaces/currency';
import { BaseModel } from './base.model';
import { AppDate } from './date.model';

export class OrderHistory extends BaseModel {
    public id: number;
    public cart_name: string;
    public items_count: number;
    public order_total: number;
    public purchaser: number;
    public state: string; // QUOTE_STATE, PRODUCTION_STATE, PENDING_STATE, COMPLETE_STATE, VOID_STATE
    public order_date: AppDate;
    public currency: Currency;
    public order_cut?: boolean;

    constructor(data) {
        super(data, {
            num: ['id', 'items_count', 'order_total'],
            date: ['order_date']
        });
    }

}
