export enum AddressType {
    Personal = 1,
    Corporate = 2
}

export enum DistributionListType {
    DistributionList = 3
}

export type AddressCategory = AddressType | DistributionListType;
export const AddressCategory = {...AddressType, ...DistributionListType};
