import {
  Component,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
  Input,
  OnInit,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Output() closed = new EventEmitter();

  @Input() title: string;
  @Input() class: string;
  @Input() headerClass: string;
  @Input() headerIcon: string;
  @Input() backdrop: boolean | 'static' = 'static';
  @Input() allowEscape = true;
  @Input() showHeader = true;
  @Input() showFooter = true;

  // @Input() set isPerforming(val: boolean) {
  // val ? this.blockUI.start() : this.blockUI.stop();
  // };

  id: string;
  contextObject: any;

  @ViewChild('modalTemplate', { static: true })
  private templateRef: TemplateRef<any>;
  private modalRef: NgbModalRef;
  private opened = false; // true when modal dialog is opened otherwise false

  constructor(private modalService: NgbModal) {
    // compute unique id
  }

  ngOnInit(): void {
    this.id = 'modal-' + Date.now() + Math.random().toString(36).substr(2);
  }

  show(obj: any = null): void {
    this.contextObject = obj;

    if (this.opened) {
      return;
    }

    this.opened = true;

    this.modalRef = this.modalService.open(this.templateRef, {
      windowClass: this.class,
      backdrop: this.backdrop,
      keyboard: this.allowEscape,
    });

    // check whether the modal has been closed by escape
    this.modalRef.result.then(
      () => {},
      () => {
        this.hide();
      }
    );
  }

  hide(): void {
    if (!this.opened) {
      return;
    }
    this.close.emit(true);
    this.opened = false;
    this.modalRef.close();
    this.closed.emit(true);
  }

  isVisible(): boolean {
    return this.opened;
  }
}
