<div class="modal-md distribution-list-modal manage-location--modal" >
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">{{dialogTitle}}</h1>
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close">
            <span class="fal fa-times" aria-hidden="true"></span>
        </button>
    </div>
    <div class="modal-body">
      <div class="container">

          <form [formGroup]="dlForm" (ngSubmit)="applyDL()" class="flex-column">
              <div class="row">
                  <div class="width-500 mx-auto">
                      <div class="form-group mb-3" [ngClass]="validationClassName(label)">
                          <label class="form-control-label font-weight-bold font-4" for="label">List Name</label>
                          <input class="form-control" id="label" maxlength="255" formControlName="label" placeholder="Enter List Name">
                          <small class="text-danger" *ngIf="dlForm.get('label').hasError('required') && (label.touched || label.dirty)">List Name is required</small>
                          <small class="text-danger" *ngIf="dlForm.get('label').hasError('maxLengthTrimmed')">Company Name / Address line 1 value exceeds character limit of 250.</small>
                      </div>
                  </div>

                  <div class="width-500 mx-auto">
                      <div class="input-group address--container p-2">
                          <div class="container-with-scroll">
                              <label class="form-control-label font-weight-bold font-4"><span>Select Addresses</span></label>
                              <div class="">
                                  <app-order-list-address-list [addresses]="editableAddressList"
                                                               [disabled]="!canEditPresetAddresses"
                                                               [hideDistributionList]="true"
                                                               (onAddressListChanged)="onAddressListChanged($event)"></app-order-list-address-list>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="text-center mt-2">
                      <button [disabled]="!canApply || inProgress"
                              type="submit"
                              class="btn btn-primary">
                          <ng-container *ngIf="inProgress"><i class="fal fa-sync rotate"></i>&nbsp;</ng-container>
                          {{isNew ? 'Create List' : 'Save'}}
                      </button>

                  </div>

              </div>

          </form>
      </div>
  </div>
</div>


