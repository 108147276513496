import {from, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import { Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { ToastrService } from 'ngx-toastr';
import {Address} from '../../../models/address.model';
import {DistributionList} from '../../../models/distribution-list.model';
import {AddressService} from '../../../services/address.service';
import {ModalService} from '../../../services/modal.service';
import {AlertType} from '../../../enums/alert-type';

@Component({
    selector: 'app-distribution-list-modal',
    templateUrl: './distribution-list-modal.component.html',
    styleUrls: ['./distribution-list-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DistributionListModalComponent implements OnInit, OnDestroy {

    public distributionList: DistributionList;
    alive = true;
    editableAddressList: Address[] = [];
    inProgress = false;
    isNew = false;

    // form items
    dlForm: UntypedFormGroup;
    label: FormControl;

    modalService: any;


    constructor(
        protected toastr: ToastrService,
        private formBuilder: UntypedFormBuilder,
        private addressService: AddressService,
        private injector: Injector,
        private activeModal: NgbActiveModal) {
        this.modalService = injector.get(ModalService);
    }


    ngOnInit() {
        this.isNew = !this.distributionList?.id;
        this.editableAddressList = cloneDeep(this.distributionList?.addresses || []);

        this.dlForm = this.formBuilder.group({
            'label': ['', Validators.compose([Validators.required])],
        });
        this.label = this.dlForm.controls['label'] as FormControl;
        this.label.setValue( this.distributionList?.label || '');
    }

    ngOnDestroy() {
        this.alive = false;
    }


    // hide and reset modal dialog
    hideModal(dl?: DistributionList) {
        this.activeModal.close(dl);
    }

    get canApply(): boolean {
        return true;
    }

    applyDL() {
        this.label.markAsTouched();
        if (!this.dlForm.valid) {
            return;
        }

        const listName = this.label.value;
        if (!this.distributionList) {
            this.distributionList = new DistributionList({label: listName, addresses: this.editableAddressList});
        } else {
            this.distributionList.label = listName;
            this.distributionList.addresses = this.editableAddressList;
        }


        this.showConfirmationDialog().pipe(
          switchMap( (result: boolean) => {
              if (!result) {
                  return of(null);
              }
              this.inProgress = true;
              return this.addressService.saveDistributionList(this.distributionList);
          }
        )).subscribe((result: DistributionList) => {
            this.inProgress = false;
            if (result) {
                const msg = `${listName} ${this.isNew ? 'has been created.' : 'has been updated.'}`;
                this.toastr.success(msg);
                this.hideModal(result);
            }
        });
    }


    private  showConfirmationDialog(): Observable<boolean> {
        if (this.isNew) {
            return of(true);
        }

        return this.addressService.testDistributionListForOrders(this.distributionList).pipe(
            switchMap( (result: boolean) => {
                if (!result) {
                    return of(true);
                }

                return from(
                    this.modalService.showAlert({
                            type: AlertType.Confirm,
                            class: 'max-width-400',
                            headerClass: 'state modal-prompt',
                            title: 'UPDATE THE DISTRIBUTION LIST?',
                            message: 'Changes to this distribution list will update the address selection and clear quantities related to removed addresses in open carts where it is in use. Do you want to proceed?',
                            actionLabel: 'Save'
                        }
                    ) as Promise<boolean>);
            }
        ));
    }

    close() {
        this.hideModal();
    }


    // preset addresses can be editable only for new orders
    get canEditPresetAddresses(): boolean {
        return true;
    }

    onAddressListChanged(addresses: Address[]) {
        this.editableAddressList = [...addresses];
    }

    get dialogTitle(): string {
        return this.isNew ? 'Create New List' : 'Edit List';
    }

    validationClassName(control: AbstractControl): string {
        if (control.touched || control.dirty) {
            return control.invalid ? 'has-danger' : '';
        }
        return '';
    }

}

