import {Component, OnInit, ViewEncapsulation, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { Order } from '../../../models/order.model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { takeWhile } from 'rxjs/operators';
import { ECOMMERCE_TYPE, PREORDER_TYPE } from '../../../constants/order-types';
import {ModalService} from '../../../services/modal.service';
import {isPaymentMethodDefined} from '../../../shared/helpers';
import {ConfigService} from '../../../services/config.service';
import {isEmptyArray} from '../../../shared/utils';
import {AddressService} from '../../../services/address.service';


@Component({
    selector: 'app-cart-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CartSelectComponent  implements OnInit, OnDestroy {


    @Input() mobile = false;
    @Input() allowAdd: boolean;

    @Input() withOrderWindowTracking = false;

    @Output() selectedOrder = new EventEmitter<Order>();

    private isNewOrder = false;
    private alive = true;
    private initialized = false;
    private creatingOrder = false;

    // private orders$: Observable<Order[]>;

    constructor(
        protected orderService: OrderService,
        protected toastr: ToastrService,
        private authService: AuthService,
        private modalService: ModalService,
        private configService: ConfigService,
        private addressService: AddressService
    ) {

    }

    ngOnInit() {
        this.init();
    }


    private init() {

        this.orderService.isInitialized().pipe(takeWhile(() => this.alive)).subscribe( result => {
            this.initialized = result;
            if (this.initialized && !this.withOrderWindowTracking) {
                this.selectOrder();
            }


        });

        if (this.withOrderWindowTracking) {
            this.orderService.buyingWindowChanged.pipe(takeWhile(() => this.alive)).subscribe( buyingWindow  => {

               if (this.orderService.currentOrderType === PREORDER_TYPE) {
                   this.selectOrder();
               }

            });

            this.orderService.orderTypeChanged.pipe(takeWhile(() => this.alive)).subscribe( orderType => {
                this.selectOrder();
            })
        }
    }
    ngOnDestroy(): void {
        this.alive = false;
    }


    selectOrder(order?: Order) {
        if (!this.initialized) {
            return;
        }
        // user selected order
        if (order) {
            this.orderService.switchActiveOrder(order).subscribe( retOrder => {
                this.selectedOrder.emit(retOrder);
                // this.addressService.setAddressesByOrderType(retOrder.findAllOrderAddresses(), this.orderType);
            });
        } else if (!this.orderService.activeOrder) {
            if (this.orderService.currentOrders.length === 0 ) {
                // this.addOrder();
                this.createFakeOrder();
            } else {
                this.orderService.switchActiveOrder(this.orderService.currentOrders[0]).subscribe( retOrder => {
                    this.selectedOrder.emit(retOrder)
                });
            }

            // this.addressService.setAddressesByOrderType([], this.orderType);
        } else {
        }
    }


    createFakeOrder() {
        const order  = this.orderService.createFakeOrder(this.orderType);
        this.selectedOrder.emit(order);
    }


    addOrder() {
        if (!this.allowAdd) {
            return;
        }

        this.creatingOrder = true;
        this.modalService.createOrderWithSettings({orderType: this.orderType}).subscribe( retOrder => {
            if (retOrder) {
                this.selectedOrder.emit(retOrder);
            }
        });
    }


    get label(): string {
        return this.orderType === ECOMMERCE_TYPE  ? 'CURRENT CART' : 'CURRENT ORDER';
    }

    get buttonLabel(): string {
        return this.orderType === ECOMMERCE_TYPE  ? 'CREATE NEW CART' : 'CREATE NEW ORDER';
    }

    get orders(): Order[] {
        return this.orderService.currentOrders;
    }

    get orderType(): string {
        return this.orderService.currentOrderType;
    }

    getOrderLabel(item: Order) {
        return item.selectLabel;
    }


    get visible(): boolean {
        return this.orderType
            && this.canOrder
            && !( this.orderType === PREORDER_TYPE && this.orderService.activeOrderWindow?.active !== true)
    }


    get activeOrder(): Order {
        return this.orderService.activeOrder;
    }

    get selectedID(): number {
        return this.activeOrder ? this.activeOrder.id : 0;
    }

    // should be present for ng-model binding
    set selectedID(val) {

    }

    isPaymentDefined(order: Order) {
        return isPaymentMethodDefined(order, this.authService.user,
            this.authService.canShowSAP && this.configService.showBillingInfo) === true;
    }

    get canOrder(): boolean {
        return this.authService.canOrder;
    }

    get canChangeOrderSettings() {
        return this.authService.canEditOrder(this.activeOrder);
    }


    get hasOrders(): boolean {
        return !isEmptyArray(this.orderService.currentOrders) && this.orderService.currentOrders.some( order => !order.isNotReal);
    }
}
