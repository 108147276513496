<ng-container *ngIf="show === 'text'">
    {{trackingData}}
</ng-container>
<ng-container *ngIf="show === 'link'">
    <a class="link link-underline small" [href]="trackingNumbers[0].url" title="Click here to view more" target="_blank">{{trackingNumbers[0].number}}</a>
    <span>{{ trackingNumbers[0].carrier_name | translate }}</span>
</ng-container>
<!--<ng-container *ngIf="show === 'vendor'">-->
<!--    <app-vendor-modal [vendorLabel]="vendor" buttonLabel="Shipped from "></app-vendor-modal>-->
<!--</ng-container>-->
<ng-container *ngIf="show === 'modal'">
    <button class="tracking--view-all" (click)="showDetails(content)">View All</button>
    <ng-template #content let-modal>
        <div class="modal-header">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">Tracking Details</h1>
            <button type="button" class="close btn btn-icon" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true" class="fal fa-times"></span>
            </button>
        </div>
        <div class="modal-body">
            <div class="container tracking--list">
                <div class="row font-weight-bold ">
                    <div class="col-3 tracking--header">Status</div>
                    <div class="col-3 tracking--header">Tracking</div>
                    <div class="col-3 tracking--header">Shipped Quantity</div>
                    <div class="col-3 tracking--header">Shipped Date</div>
                </div>
                <div class="row">
                    <div class="col-12"></div>
                </div>

                <ng-container *ngFor="let track of trackingNumbers">
                    <div class="row">
                        <div class="col-3 tracking--state" [ngClass]="{delivered: isDelivered(track.status)}" [innerHTML]="track.status | translate"></div>
                        <div class="col-3 tracking--link">
                            <ng-container *ngIf="track.mode === 'link'">
                                <a class="link link-underline small" *ngIf="track.mode === 'link'" [href]="track.url" title="Click to view more" target="_blank">{{track.number}}</a>
                                <span> {{track.carrier_name | translate}}</span>
                            </ng-container>
<!--                            <ng-container *ngIf="track.mode === 'vendor'">-->
<!--                                <app-vendor-modal [vendorLabel]="vendor" buttonLabel="Shipped from "></app-vendor-modal>-->
<!--                            </ng-container>-->
                        </div>
                        <div class="col-3 tracking--state text-center"  [innerHTML]="track.quantity"></div>
                        <div class="col-3 tracking--state text-center"  [innerHTML]="track.shipping_date | dateFormat"></div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-template>
</ng-container>
