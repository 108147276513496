<div class="search-container">
    <div class="d-inline-block search-dropdown" *ngIf="canChooseOrderType">
        <ng-select  class="order-type--select font-5"
                   [items]="orderTypes" bindValue="id"  [searchable]="false"
                   [(ngModel)]="selectedOrderType"
                   (change)="selectOrderType($event)"
                   [clearable]="false">
            <ng-template ng-label-tmp let-item="item">
                {{ item.label }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                {{ item.label }}
            </ng-template>
        </ng-select>
    </div>
    <div class="search-input tooltip-container">
        <input type="text"
            placeholder="Enter at least 3 characters"
            autocomplete="off"
            class="form-control"
            [(ngModel)]="searchText"
            [ngbTooltip]="inputTooltip"
            tooltipClass="tooltips"
            placement="auto"
            triggers="manual"
            (input)="handleInput()"
            #tooltip="ngbTooltip">
        <button *ngIf="searchText" class="btn-close" (click)="clearSearch()">
            <i class="fal fa-times"></i>
        </button>
        <button class="search-button" title="Search" (click)="search()">
            <i class="fal fa-search"></i>
        </button>
    </div>
</div>
<div *ngIf="showBackdrop" class="global-search__cover" [class.is-active]="true" (click)="close()">
</div>