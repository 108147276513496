// can be reused later for internet
// import {environment} from '../../environments/environment';

export const SHIPPING_INFO = `*The shipping amount displayed is estimated.  Actuals will be invoiced at the time of delivery.`;

export const TAX_EXEMPT_INFO = `One or more items have tax applied. this could be due to the location shipped to, the item type`;

export const AUTO_SHIP_INFO = `Auto-Ship requests may have orders placed once the item is approved and ready to be produced. 
All requested quantities must be ordered. To send remaining quantities to IMS, please add the quantity remaining and ship to IMS BTI 
warehouse. You may complete your order once all quantities are exhausted.`;

export const AUTO_SHIP_GROUP_INFO = `Please add at least one item to your request for approval. Once you have completed 
adding all your items to request, you may save and come back later or save and submit you request. Once all of your items are priced 
and approved  you will be able to add your shipments and complete your order.`;

export const AUTO_SHIP_REQUEST_INFO = `Please fill out the form below to request a new Auto-Ship item. If you are requesting a currently 
available item you may use the search field to pre-fill the required fields. If you item is not a currently available item, please fill 
in all the required fields. Once requested your request will be processed on the order in which it was received. Order creation will be 
available once the item is approved and ready.`;

export const AUTO_SHIP_SUBMISSION_INFO = `Thank you for your submission. You will be notified of any status changes.
You may create orders for this item once the item is approved and ready.`;

export const BACKORDER_ALL_ITEMS = 'All items added to your cart are on order and will ship when it is in stock.';

export const BACKORDER_PARTIAL_ITEMS = '${0} item(s) added to your cart are on order and will ship when it is in stock.';

export const DISCOUNT_INFO = 'Shipping and Taxes are calculated based upon regular Price. Promo codes can not be combined.';



export const ADDRESS_BOOK_INFO = `The default address is set for only your account. Please input only unique address location information below. Your addresses will be validated when entered. Please do not attempt to enter attention or PO information in your address.`;

export const WSLR_ADDRESS_BOOK_INFO = `This is the %WSLR_NAME% Shared Address Book. The default address is set for the entire organization and is used by others to create orders for this organization. Please input only unique address location information below. Your addresses will be validated when entered. Please do not attempt to enter attention or PO information in your address.`;

export const SAFARI_NOT_SUPPORTED = 'Please use Chrome on a desktop to add a new Credit Card.';

export const ORDER_NOTES_INFO = 'Enter internal notes for your personal use to show up on your packing slip and order history. These notes are not used by the Supplier.';


export  const OD_ORDER_RESTRICTED_PRODUCTS = 'There Are Unavailable Items in Your Cart. Please Remove Before Checking Out.';

export  const BW_ORDER_RESTRICTED_PRODUCTS = 'There Are Unavailable Items in Your Cart. Please Remove them.';


export const PRODUCT_RESTRICTED = 'This Item is No Longer Available';

export const PRODUCT_REQUIRES_APPROVAL = 'This Product Requires Approval';
export const ORDER_OUT_OF_STOCK = 'This Item is currently out of stock.'


export const ORDERS_DEADLINE =
  `<div class='text-center'><h3>Key ordering deadlines for end of year planning:</h3> <p>Order by 12/1 to ensure H1 delivery & billing.<br/> Orders after 12/2 will be delivered in January.</p></div>`
