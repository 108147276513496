<div class="wrapper-inner no-overflow-x" *mobxAutorun>
    <div class="container not-found">
        <h1 class="title mb-4">
          That page doesn't exist
        </h1>

        <div class="search--container" >
            <app-search></app-search>
        </div>
    </div>
</div>
<app-footer></app-footer>
<app-back-top position="200"></app-back-top>
