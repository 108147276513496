<div class="modal-md address">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">CONTACT DETAILS</h1>
        <button type="button"  class="btn btn-icon close" (click)="close()" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <div class="contact-details-info-wrapper">
            <div *ngFor="let contact of contactDetails" class="contact-details--item">
                <div class="contact-details-box">
                    <span class="label">Name</span>
                    <div class="contact-details-value">{{contact.contact}}</div>

                </div>
                <div class="contact-details-box">
                    <span class="label">Email</span>
                    <div class="contact-details-value">{{contact.email}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
