<div class="d-flex" *ngIf="!hideDistributionList">
    <div class="mr-4">
        <input class="radio-dark-gray mr-2"
               name="address-list" type="radio"
               id="address-list"
               [(ngModel)]="selectionType"
               (change)="selectionTypeChanged()"
               value="addresses"><label for="address-list">Address Selection</label>
    </div>
    <div>
        <input class="radio-dark-gray mr-2"
               name="dl-list" type="radio"
               id="dl-list"
               value="dl-list"
               [(ngModel)]="selectionType"
               (change)="selectionTypeChanged()">
        <label for="dl-list">Distribution List</label>
    </div>


</div>
<ng-container *ngIf="selectionType=== 'addresses'">
    <ng-container *ngIf="!hasAddresses">
        <div class='empty--container'>
            <a class="link link-underline font-5" (click)="onAddAddressHandler()" title="Add Default Address" href="javascript:void(0)">{{addAddressLabel}} <i class="fas fa-plus"></i></a>
        </div>
    </ng-container>
    <ng-container *ngIf="hasAddresses">
        <div class="address--scroll--container">
            <app-order-list-address-item *ngFor='let address of addresses; index as i;'
                                         [address]='address' [showAddButton]="i === addresses.length - 1"
                                         [disabled]="disabled"
                                         (anotherAddressAdded)="onAddAddressHandler()"
                                         (addressUpdated)="onAddressUpdatedHandler($event,i)"
                                         (addressRemoved)="onRemoveAddressHandler(i)"></app-order-list-address-item>
        </div>
    </ng-container>

</ng-container>

<ng-container *ngIf="selectionType === 'dl-list'">
    <app-distribution-list-select [distributionList]="distributionList"
        (onDistributionListChanged)="onDistributionListChanged($event)"></app-distribution-list-select>
</ng-container>


