import {Component, ViewEncapsulation, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import {OrderService} from '../../../services/order.service';
import {ConfigService} from '../../../services/config.service';
import {ModalService} from '../../../services/modal.service';
import {ToastrService} from 'ngx-toastr';
import {from, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap, takeWhile, tap} from 'rxjs/operators';

import {Order} from '../../../models/order.model';
import {OrderItem} from '../../../models/order-item.model';
import {OrderItemDelivery} from '../../../models/order-item-delivery.model';
import {EXTRA_INTERNAL_ORDER_LENGTH, INTERNAL_ORDER_LENGTH} from '../../../constants/globals';
import {Features} from '../../../interfaces/features';
import {wbsIOSymbolsAllowed} from '../../../shared/helpers';

@Component({
  selector: 'app-internal-order-code',
  templateUrl: './internal-order-code.component.html',
  styleUrls: ['./internal-order-code.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InternalOrderCodeComponent implements OnInit, OnDestroy {

  searchSubject: Subject<string> = new Subject<string>();
  alive = true;
  filteredCodes: string[]  = [];
  selectedCode: string;
  inProcess = false;

  @Input() disabled = false;
  @Input() order: Order;
  @Input() orderItem: OrderItem;
  @Input() delivery: OrderItemDelivery;
  @Input() shouldUpdateOrder  =  false;
  @Input() readonly = false;
  @Input() showWithModalDialog = false;

  @Input() class = '';
  @Output() changed =  new EventEmitter<void>();

  constructor(private configService: ConfigService,
              private toastr: ToastrService,
              private orderService: OrderService,
              private modalService: ModalService) { }


  ngOnInit() {

    this.filteredCodes = [...this.internalCodes];
    this.selectedCode = !!this.delivery?.internal_order ? this.delivery?.internal_order : null;

    this.searchSubject.pipe(
      takeWhile( () => this.alive),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        if (!term) {
          return of(this.internalCodes);
        }
        const termLowerCase = term.toLowerCase();
        const foundCodes  =  this.internalCodes.filter( code => code.toLowerCase().indexOf(termLowerCase) > -1);
        return of(foundCodes);
      })
    ).subscribe( (retCodes: string[]) => {
      this.filteredCodes = retCodes;
    });
  }


  ngOnDestroy() {
    this.alive = false;
  }


  get showInternalOrderCodes(): boolean {
    return this.configService.features.showInternalOrderCodes;
  }

  get internalCodes(): string[] {
    return this.orderService.internalOrderCodes;
  }

  onInternalOrderChanged(newValue: string) {
    const value  =  !!newValue ? newValue.trim() : '';
    if (this.order.isWBSDefined) {
      this.delivery.wbs = value;
    } else {
      this.delivery.internal_order = value;
    }
    this.orderItem.updateDelivery(this.delivery, this.order.isOnDemand);
    if (this.shouldUpdateOrder) {
      this.saveInternalOrder();
    }

    this.changed.emit();
  }

  onInternalOrderReset() {
    this.delivery.wbs = '';
    this.delivery.internal_order = '';
    this.orderItem.updateDelivery(this.delivery, this.order.isOnDemand);
  }

  onSelect(data) {
    if (data) {
      this.selectedCode = data;
      this.onInternalOrderChanged(data);
    }
  }

  private saveInternalOrder() {
    const  updateOrderItemDelivery = cloneDeep(this.delivery);

    if (updateOrderItemDelivery.quantity > 0 && !updateOrderItemDelivery.internalOrder) {
      const warningMsg = `${this.internalOrderLabel} is required`;
      this.toastr.warning(warningMsg, 'Warning');
      return;
    }


    if (this.features.showInternalOrderCodes) {
      // validate length of wbd and internal order
      if (updateOrderItemDelivery.internalOrder.length > EXTRA_INTERNAL_ORDER_LENGTH) {
        this.toastr.warning(`Internal Order should not exceed ${EXTRA_INTERNAL_ORDER_LENGTH} symbols.`, 'Warning');
        return;
      }

    } else {
      if (this.features.showWBS) {
        // validate length of wbd and internal order
        if (updateOrderItemDelivery.internalOrder && updateOrderItemDelivery.internalOrder !== '0') {
          if (updateOrderItemDelivery.internalOrder.length > INTERNAL_ORDER_LENGTH) {
            this.toastr.warning(`Internal Order should not exceed ${INTERNAL_ORDER_LENGTH} symbols.`, 'Warning');
            return;
          }
          if (!wbsIOSymbolsAllowed(updateOrderItemDelivery.internalOrder)) {
            const ioLabel = this.order?.isWBSDefined ? 'WBS' : 'Internal Order';
            this.toastr.warning(`${ioLabel} allows  only digits, letters, /, #, -, . and space`);
            return;
          }
        }
      }
    }

    // check for both flags
    if (this.features.showInternalOrderCodes || this.configService.features.showWBS) {
      const uniqueData: string[] = [];
      const  getInternalOrder = (delivery: OrderItemDelivery): string => {
        // if delivery WBS is no defined, then order WBS is used
        return  delivery.internalOrder || this.order.internalOrder
      }

      // not for deilveries with 0 qty
      const deliveries = this.orderItem.deliveries.filter( d => d.quantity > 0);
      for (const delivery of deliveries) {
        const uniqueKey = (delivery.addr_id || '') + '_' + getInternalOrder(delivery);
        if (!uniqueData.includes(uniqueKey)) {
          uniqueData.push(uniqueKey);
        } else {
          this.toastr.warning(
              `You cannot have the same address and ${this.internalOrderLabel} for multiple deliveries`,
              'Warning');
          return;

        }
      }
    }


    this.inProcess = true;
    this.orderService.updateOrderItem(this.order, this.orderItem)
        .subscribe(data => {
          this.inProcess = false;
          if (data) {
            this.toastr.success(`${this.internalOrderLabel} has been updated.`);
          }
        });
  }

  get features(): Features {
    return this.configService.features;
  }

  get internalOrderLabel(): string {
    if (this.order.isNotReal) {
      // fake order
      return 'Order Code';
    }
    if (this.features.showInternalOrderCodes) {
      return 'Internal Order';
    }

    return this.order.isWBSDefined ? 'WBS' : 'Internal Order';
  }

  showIOEditModal() {
    from(this.modalService.showInternalOrderModal(this.order, this.orderItem, this.delivery))
        .subscribe( result => {
          if (result) {
            if (this.order.isWBSDefined) {
              this.delivery.wbs = result.internalOrder;
            } else {
              this.delivery.internal_order = result.internalOrder;
            }
          }
        })
  }

  get defaultInternalOrder(): string {
    return this.delivery.internalOrder || this.order.internalOrder;
  }

  get isItemCut(): boolean {
    return this?.orderItem?.item_cut == true ? true : false;
  }
}
