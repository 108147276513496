import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';
import {ECOMMERCE_TYPE, PREORDER_TYPE} from '../constants/order-types';




@Injectable()
export class MultiTenantService {

    constructor(private configService: ConfigService) {}


    public get logoImageUrl(): string {
        return this.configService.features.logoUrl;
    }


    public get orderWindowLabel(): string {
        return this.configService.features.orderWindowLabel;
    }

    public get onDemandLabel(): string {
        return this.configService.features.onDemandLabel;
    }

    public get projectLabel(): string {
        return this.configService.features.clientName;
    }


    public get canShowWBS(): boolean {
        return this.configService.features.showWBS;
    }

    public get catalogLabel(): string {
        // if  on demand  label ==  catalog  no need to add catalog word
        if (this.configService.features.onDemandLabel.toLowerCase().includes('catalog')) {
            return this.configService.features.onDemandLabel;
        }

        return `${this.configService.features.onDemandLabel} Catalog`;
    }

    public get showDeliveryComments(): boolean {
        return this.configService.features.showDeliveryComments;
    }

    public get showOrderNotes(): boolean {
        return this.configService.features.showOrderNotes;
    }
    public get showPlanningComments(): boolean {
        return this.configService.features.showPlanningComments;
    }


    public get showOrderNotesOrDeliveryComments(): boolean {
        return this.configService.features.showOrderNotes || this.configService.features.showDeliveryComments;
    }

    public get orderNotesLabel(): string {
        return this.showDeliveryComments ? 'Delivery Comments' : 'Order Notes';
    }

    public get productCodeLabel(): string {
        return this.configService.features.productCodeLabel;
    }

    public get orderTypes(): any {
        const ORDER_TYPES = [
            {type: PREORDER_TYPE, label: `${this.configService.features.orderWindowLabel} Order`},
            {type: ECOMMERCE_TYPE, label: `${this.configService.features.onDemandLabel} Order`},
        ]

        return ORDER_TYPES;
    }

    public get quickOrderTypes(): any {
        const QUICK_ORDER_TYPES = [
            {type: PREORDER_TYPE, label: `${this.configService.features.orderWindowLabel} Order`},
            {type: ECOMMERCE_TYPE, label: `${this.configService.features.onDemandLabel} Order`},
        ];
        return QUICK_ORDER_TYPES;
    }

    public get productWrongWindowLabel(): string {
        return  `The product does not belong to active ${this.orderWindowLabel}. Please select a correct ${this.orderWindowLabel}`;

    }

    public getMappedBreadcrumbName(name: string): string {
        if (!name) {
            return '';
        }

        if (!name.startsWith('__')) {
            return name;
        }

        return name.replace('__BW__', this.orderWindowLabel).replace('__OD__', this.onDemandLabel);
    }

    public getMappedTitle(title: string): string {
        if (!title) {
            return '';
        }

        if (!title.startsWith('__')) {
            return title;
        }

        return title.replace('__BW__', this.orderWindowLabel).replace('__OD__', this.onDemandLabel);
    }

}

