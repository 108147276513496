import cloneDeep from 'lodash/cloneDeep';
import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Address} from '../../../../models/address.model';
import {isEmptyArray} from '../../../../shared/utils';
import {AddressService} from '../../../../services/address.service';
import {ToastrService} from 'ngx-toastr';
import {DistributionList} from '../../../../models/distribution-list.model';

@Component({
  selector: 'app-order-list-address-list',
  templateUrl: './order-list-address-list.component.html',
  styleUrls: ['./order-list-address-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderListAddressListComponent implements OnInit {
  selectionType: 'addresses' | 'dl-list' = 'addresses';

  @Input() addresses: Address[] = [];
  @Input() distributionList: DistributionList;
  @Input() disabled = false;
  @Input() hideDistributionList = false;

  @Output() onAddressListChanged = new EventEmitter<Address[] | DistributionList>();

  constructor(private addressService: AddressService, private toastr: ToastrService) {
  }

  get hasAddresses(): boolean {
    return !!this.addresses && !isEmptyArray(this.addresses);
  }

  ngOnInit(): void {
    if (this.distributionList) {
      this.selectionType = 'dl-list';
    }
  }


  onRemoveAddressHandler(index: number): void {
    const newAddresses = this.addresses.filter((_, i) => i !== index);
    this.addresses = newAddresses;
    this.onAddressListChanged.emit(newAddresses);
  }

  onAddAddressHandler(): void {
    let newAddresses: Address[] = [];
    const allAddresses = this.addressService.addresses;
    if (isEmptyArray(allAddresses)) {
      // no addresses available
      this.toastr.warning('No addresses available, Please add new address first');
      return;
    }
    const computedAddressList = allAddresses;
    if (isEmptyArray(computedAddressList)) {
      // no addresses available
      this.toastr.warning('No addresses available, Please add new address first');
      return;
    }

    const selectedAddressesIds: number[]   = this.addresses.map((address: Address) => address.id);

    const availableAddresses = computedAddressList.filter((address: Address) => !selectedAddressesIds.includes(address.id));
    if (isEmptyArray(availableAddresses)) {
      /// no more addresses available pickup first one
      newAddresses = [...this.addresses, computedAddressList[0]];
    } else {
      newAddresses = [...this.addresses, availableAddresses[0]];
    }

    this.onAddressListChanged.emit(newAddresses);
  }


  onAddressUpdatedHandler(address: Address, index): void {
    const newAddresses = cloneDeep(this.addresses);
    // find index by address id
    newAddresses[index] = address;
    this.addresses = newAddresses;
    this.onAddressListChanged.emit(newAddresses);
  }

  get addAddressLabel(): string  {
    return isEmptyArray(this.addresses) ? 'Add Address' : 'Add Another Address';
  }

  selectionTypeChanged() {
    if (this.selectionType === 'addresses') {
      this.onAddressListChanged.emit([...this.addresses]);
    } else {
      this.onAddressListChanged.emit(this.distributionList);
    }
  }

  onDistributionListChanged($event: DistributionList) {
    this.onAddressListChanged.emit($event);
  }
}
