<ng-container *ngIf="!mobile">
    <div class="order-details box-shadow">

        <div class="d-flex align-items-center">
            <div class="order-details--title font-3">
                <span>Your Orders Placed for this Item</span>
            </div>
            <div *ngIf="!modal">
<!--                <button class="btn btn-icon large with-border with-shadow background-white mr-2" title="Export to Excel" (click)="exportToExcel.emit()"><i class="fal fa-file-excel"></i></button>-->
<!--                <button class="btn btn-icon large with-border with-shadow background-white" title="Export to PDF" (click)="exportToPdf.emit()"><i class="fal fa-file-pdf"></i></button>-->
            </div>
            <button *ngIf="modal" class="btn btn-icon with-border with-shadow background-white" (click)="showDetails.emit()">
                <i class="fas fa-chevron-right"></i>
            </button>
        </div>
        <ng-container *ngIf="!modal">
            <div class="d-flex full-width font-5 py-2 line-separator--light-grey line-up-separator--light-grey mt-3">
                <div class="qty-field">
                    <label class="m-0">Qty</label>
                </div>
                <div class="order-field">
                    <label class="m-0">Order #</label>
                </div>
                <div class="purchaser-field">
                    <label class="m-0">Purchaser</label>
                </div>
                <div class="wslr-field" *ngIf="hasWslr">
                    <label class="m-0">WSLR #</label>
                </div>
                <div class="name-field" [ngClass]="{'empty-wslr': !hasWslr}">
                    <label class="m-0">Order Name</label>
                </div>
            </div>
            <div *ngFor="let info of orderDetails.info" class="d-flex line-separator--light-grey py-3">
                <div class="qty-field">
                    {{ info.shipment_qty }}
                </div>
                <div  class="order-field font-5 font-weight-bold">
                    {{ info.order_id }}
                </div>
                <div class="purchaser-field font-5 font-weight-bold">
                    {{ info.purchaser }}
                </div>
                <div class="wslr-field font-5 font-weight-bold" *ngIf="hasWslr">
                    {{ info.wslr_id || '' }}
                </div>
                <div class="name-field font-5 font-weight-bold"  [ngClass]="{'empty-wslr': !hasWslr}">
                    <span [innerHTML]="info.cart_name | safeContent"></span>
                </div>
            </div>
            <p class="font-5 my-2"><ng-container *ngIf="hasWslr">Some people may have ordered this item for this Wholesaler # within this window.</ng-container>
                This quantity does not include any quantities not yet added to your current order.</p>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="mobile">
    <div class="order-details box-shadow">

        <div class="d-flex align-items-center line-separator--light-grey pb-3">
            <div class="order-details--title font-3">
                <span>Your Orders Placed for this Item</span>
            </div>
            <div>
<!--                <button class="btn btn-icon large with-border with-shadow background-white mr-2" title="Export to Excel" (click)="exportToExcel.emit()"><i class="fal fa-file-excel"></i></button>-->
<!--                <button class="btn btn-icon large with-border with-shadow background-white" title="Export to PDF" (click)="exportToPdf.emit()"><i class="fal fa-file-pdf"></i></button>-->
            </div>
        </div>
        <div *ngFor="let info of orderDetails.info" class="d-flex line-separator--light-grey py-3 flex-column mobile-view">
            <div class="font-5 my-2">
                <label class="m-0">Qty:</label>
                <span class="font-weight-bold font-4">{{ info.shipment_qty }}</span>
            </div>
            <div  class="font-5 my-2">
                <label class="m-0">Order #</label>
                <span class="font-weight-bold">{{ info.order_id }}</span>
            </div>
            <div class="font-5 my-2">
                <label class="m-0">Purchaser:</label>
                <span class="font-weight-bold">{{ info.purchaser }}</span>
            </div>
            <div class="font-5 my-2" *ngIf="hasWslr">
                <label class="m-0">WSLR #</label>
                <span class="font-weight-bold">{{ info.wslr_id || '' }}</span>
            </div>
            <div class="font-5 my-2">
                <label class="m-0">Order Name:</label>
                <span class="font-weight-bold" [innerHTML]="info.cart_name | safeContent"></span>
            </div>
        </div>
        <p class="font-5 my-2">
            <ng-container *ngIf="hasWslr">Some people may have ordered this item for this Wholesaler # within this window.</ng-container>
            This quantity does not include any quantities not yet added to your current order.</p>
    </div>
</ng-container>
