<div class="">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">Question About Decision Points?</h1>
        <button type="button"  class="btn btn-icon close" (click)="close()" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">

        <div class="py-1 px-3">
            <p>
                <strong>Addresses:</strong>&nbsp;The default address used for a Wholesaler may not align with the AB-provided shipping address. If an address is incorrect, please ask the Wholesaler to adjust their default address before proceeding with your order. If the order is created, you are responsible for verifying the accuracy of the shipment locations.
            </p>
            <p>
                <strong>Eligibility:</strong>&nbsp;Wholesalers in the Decision Point may not be eligible due to brand rights, state, or other restrictions.
            </p>

            <p>
                <strong>Review:</strong>&nbsp; Go to <a href="/reports" class="link link-underline">Reports</a> page and check the Decision Points Report for further information about Wholesalers and their addresses.
            </p>

            <div class="text-center mt-3">
                Contact your support team for assistance.
            </div>
        </div>

    </div>
</div>

