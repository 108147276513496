import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { ECOMMERCE_TYPE, PREORDER_TYPE } from '../../../../constants/order-types';
import { Pages } from '../../../../enums/pages.enum';
import { SearchService } from '../../../../services/search.service';
import { OrderService } from '../../../../services/order.service';
import { Router, NavigationEnd} from '@angular/router';
import { MultiTenantService } from '../../../../services/multi-tenant.service';
import { BehaviorSubject, Subscription} from 'rxjs';

const MIN_SYMBOLS_TO_SEARCH = 3;

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderSearchComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('tooltip', { static: false }) tooltip; // Get tooltip reference

  // Static BehaviorSubject to retain the latest value
  static orderType = new BehaviorSubject<string>(PREORDER_TYPE);
  private routerSubscription: Subscription;

  orderTypes = [];
  searchText = '';

  @Output() closed = new EventEmitter<void>();

  @Input() showBackdrop = false;
  @Input() set pageType(val: Pages) {    
    // Logic to handle page type (if needed)
  }

  constructor(
    private authService: AuthService,
    private searchService: SearchService,
    private orderService: OrderService,
    private multiTenantService: MultiTenantService,
    private router: Router
  ) {
    // Initialize order types
    this.orderTypes = [
      { id: PREORDER_TYPE, label: this.multiTenantService.orderWindowLabel },
      { id: ECOMMERCE_TYPE, label: this.multiTenantService.onDemandLabel },
    ];
  }

  ngOnInit(): void {  
    // Listen for route changes
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd ) {
        if (!event.urlAfterRedirects.includes('/search-results'))
          this.clearSearch();
      }
    }); 
    
  }
  ngOnDestroy(): void {
    // Cleanup subscription
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  ngAfterContentInit(): void {
    // Subscribe to orderType updates
    HeaderSearchComponent.orderType.subscribe((val: string) => {
      this.orderService.currentOrderType = val; // Sync with orderService
    });
  }

  ngAfterViewInit(): void {
    // Subscribe to orderType updates
    HeaderSearchComponent.orderType.subscribe((val: string) => {
      this.orderService.currentOrderType = val; // Sync with orderService
    });
  }

  get canChooseOrderType(): boolean {
    return this.authService.canBuyingWindow;
  }

  selectOrderType(orderType) {
    const selectedType = orderType?.id || PREORDER_TYPE;
    this.orderService.currentOrderType = selectedType;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.search();
    }   
  }

  handleInput() {
    if (this.searchText.length < MIN_SYMBOLS_TO_SEARCH && this.searchText.length > 0) {
      this.tooltip?.open();
    } else {
      this.tooltip?.close();
    }
  }

  get inputTooltip():string {
    if (this.searchText.length < MIN_SYMBOLS_TO_SEARCH || this.searchText.length > 1) {
      return "Enter at least 3 characters to search";
    }
    return '';
  }
  
  search() {
    this.searchText = this.searchText.trim();

    if (this.searchText && this.searchText.length >= MIN_SYMBOLS_TO_SEARCH) {
      this.searchService.globalTerm = this.searchText;
      this.searchService.currentBuyingWindowId = this.orderService.currentOrderWindowId;

      this.searchService.productsSearch().subscribe(() => {});
      if (!this.isSearchResultPage) {
        this.router.navigate(['/search-results']).then(() => {});
      }
    } else if (this.searchText === '') {
      this.searchService.clearSearchResults();
    }
  }

  get isSearchResultPage(): boolean {
    return this.router.url.indexOf('search-result') !== -1;
  }

  get selectedOrderType(): string {
    return this.orderService.currentOrderType || PREORDER_TYPE;
  }

  set selectedOrderType(orderType: string) {      
    // Emit the value to the static BehaviorSubject
    HeaderSearchComponent.orderType.next(orderType);
  }

  clearSearch() {
    this.searchText = '';
  }

  close() {
    this.closed.emit();
  }
}
